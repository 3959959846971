import {
  Avatar,
  Box,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";

interface FieldPeopleProps {
  costumer: string;
  fieldman: string;
}

const FieldPeople = ({ costumer, fieldman }: FieldPeopleProps) => {
  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="overline">
              Customer
            </Typography>
            <Typography variant="h4">{costumer}</Typography>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: "success.main",
              height: 56,
              width: 56,
            }}
          >
            <SvgIcon>
              <PeopleIcon />
            </SvgIcon>
          </Avatar>
        </Stack>

        <Box sx={{ mt: 3 }}>
          <Typography color="text.secondary" variant="caption">
            {fieldman}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default FieldPeople;
