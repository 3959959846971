import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Autocomplete,
  TextField,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import SwiperImage from "../../components/SwiperImg/SwiperImg";
import { useSelector } from "react-redux";
import {
  selectManifoldData,
  selectManifoldSelected,
  setManifoldSelected,
} from "./field-detail-slice";
import { useAppDispatch } from "src/app/hooks";
import { ALERT_STATUS_LABELS } from "src/constants/dictionary";
import { useEffect, useState } from "react";

interface FieldManifoldProps {
  dates: [{ label: string; id: number }] | [];
}

const FieldManifold = ({ dates }: FieldManifoldProps) => {
  const dispatch = useAppDispatch();
  const manifoldSelected = useSelector(selectManifoldSelected);
  const manifoldData = useSelector(selectManifoldData);
  const [manifoldImages, setManifoldImages] = useState<
    { src: string; title: string; landscape?: boolean }[]
  >([]);

  useEffect(() => {
    const images = [];

    if (manifoldData.generalLgUrl) {
      images.push({
        src: manifoldData.generalLgUrl,
        title: "General",
        landscape: true,
      });
    }
    if (manifoldData.femaleLgUrl) {
      images.push({ src: manifoldData.femaleLgUrl, title: "Female" });
    }
    if (manifoldData.male1LgUrl) {
      images.push({ src: manifoldData.male1LgUrl, title: "Male 1" });
    }
    if (manifoldData.male2LgUrl) {
      images.push({ src: manifoldData.male2LgUrl, title: "Male 2" });
    }
    if (manifoldData.male3LgUrl) {
      images.push({ src: manifoldData.male3LgUrl, title: "Male 3" });
    }
    if (manifoldData.extraLgUrl) {
      images.push({ src: manifoldData.extraLgUrl, title: "Extra" });
    }

    setManifoldImages(images);
  }, [manifoldData]);

  const form = {
    soilPreparation: "Good",
    transplant: "Good",
    population: "Good",
    weedsControl: "Good",
    pestsControl: "Good",
    plantsDevelopment: "Good",
    irrigation: "Good",
    diseaseControl: "Good",
    beeActivity: "Good",
    nicking: "Good",
    fecundation: "Good",
    harvest: "Good",
    recommendationFulfillment: "Good",
    treatmentComment: "Good",
    generalStatusComment: "Good",
    shouldSendToGrower: "Good",
    performance: "Good",
    performanceComment: "Good",
    generalStatus: "Good",
  };
  const handleDateSelect = (
    event: React.SyntheticEvent<{}>,
    newValue: {} | null
  ) => {
    dispatch(setManifoldSelected(newValue));
  };

  const getAlertStatusLabel = (status: string) => {
    return ALERT_STATUS_LABELS[status] || ALERT_STATUS_LABELS.default;
  };

  const getDefaultText = (value: string | number | null) => {
    if (value === null) {
      return "-";
    }
    return value;
  };

  const getRecommendationFulfillment = (value: string | number | null) => {
    if (value === null) {
      return "-";
    } else if (value === 1 || value === "1") {
      return "Done";
    }
    return "Pending";
  };

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        title="Manifolds"
        action={
          <Autocomplete
            options={dates}
            sx={{ width: 250 }}
            getOptionLabel={(option) => option.label}
            onChange={handleDateSelect}
            // value={manifoldSelected}
            renderInput={(params) => (
              <TextField key={params.id} {...params} label="Date" />
            )}
          />
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ fontSize: "12px" }}>
                      General Status:
                    </TableCell>
                    <TableCell style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {getAlertStatusLabel(manifoldData.generalStatus)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontSize: "12px" }}>
                      Performance:
                    </TableCell>
                    <TableCell style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {!manifoldData.performance ||
                      manifoldData.performance === ""
                        ? "-"
                        : parseFloat(manifoldData.performance)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontSize: "12px" }}>
                      Soil Preparation:
                    </TableCell>
                    <TableCell style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {getDefaultText(manifoldData.soilPreparation)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontSize: "12px" }}>
                      Transplant:
                    </TableCell>
                    <TableCell style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {getDefaultText(manifoldData.transplant)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontSize: "12px" }}>
                      Population:
                    </TableCell>
                    <TableCell style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {getDefaultText(manifoldData.population)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontSize: "12px" }}>
                      Weeds Control:
                    </TableCell>
                    <TableCell style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {getDefaultText(manifoldData.weedsControl)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontSize: "12px" }}>
                      Pests Control:
                    </TableCell>
                    <TableCell style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {getDefaultText(manifoldData.pestsControl)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontSize: "12px" }}>
                      Plants Development:
                    </TableCell>
                    <TableCell style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {getDefaultText(manifoldData.plantsDevelopment)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontSize: "12px" }}>
                      Irrigation:
                    </TableCell>
                    <TableCell style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {getDefaultText(manifoldData.irrigation)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontSize: "12px" }}>
                      Disease Control:
                    </TableCell>
                    <TableCell style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {getDefaultText(manifoldData.diseaseControl)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={3}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ fontSize: "12px" }}>
                      Bee Activity:
                    </TableCell>
                    <TableCell style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {getDefaultText(manifoldData.beeActivity)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontSize: "12px" }}>Nicking:</TableCell>
                    <TableCell style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {getDefaultText(manifoldData.nicking)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontSize: "12px" }}>
                      Fecundation:
                    </TableCell>
                    <TableCell style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {getDefaultText(manifoldData.fecundation)}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell style={{ fontSize: "12px" }}>Harvest:</TableCell>
                    <TableCell style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {getDefaultText(manifoldData.harvest)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontSize: "12px" }}>
                      Recommendation Fulfillment:
                    </TableCell>
                    <TableCell style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {getRecommendationFulfillment(
                        manifoldData.recommendationFulfillment
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontSize: "12px" }}>
                      Treatment:
                    </TableCell>
                    <TableCell style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {manifoldData.treatmentComment}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontSize: "12px" }}>
                      General Status Comment:
                    </TableCell>
                    <TableCell style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {form.generalStatusComment}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={6}>
            <SwiperImage images={manifoldImages} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default FieldManifold;
