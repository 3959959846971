
import { Box, Typography } from "@mui/material";
import ReportTable from "./ReportComponents/ReportTable";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchReportsThunk } from "./report-slice";
import { fetchFieldsManifoldsPH } from "src/services/field";
const ReportScreen = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchReportsThunk())
    fetchFieldsManifoldsPH([1])
  }
  , [dispatch])
  return (
  <Box p={2}>
      <Typography variant="h4" gutterBottom>
        Report
      </Typography>
      <Box>
        <ReportTable /> 
        </Box>
     </Box>)
      
}

export default ReportScreen;