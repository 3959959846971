import { Box, Container, Stack, Typography } from "@mui/material";
import FieldsTable from "./FieldsTable";
import { useAppDispatch } from "src/app/hooks";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import { fetchFieldsThunk, selectFieldsState } from "./field-slice";
import { applyPagination } from "src/utils/apply-pagination";
import { useFieldsFilter } from "./FieldsUtils/useFieldsFilter";

const useFields = (data: any, page: any, rowsPerPage: any) => {
  return useMemo(() => {
    return applyPagination(data, page, rowsPerPage);
  }, [page, rowsPerPage, data]);
};

const Page = () => {
  const dispatch = useAppDispatch();
  const {
    fieldsFiltered,
    totalsGeneralStatus,
    totalsPerformanceStatus,
    fieldActionStatus,
  } = useSelector(selectFieldsState);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const content = useFieldsFilter(fieldsFiltered, searchText);
  const contentPage = useFields(content, page, rowsPerPage);

  useEffect(() => {
    dispatch(fetchFieldsThunk());
  }, [dispatch]);

  const handlePageChange = useCallback((event: any, value: any) => {
    setPage(value);
  }, []);

  const handleRowsPerPageChange = useCallback((event: any) => {
    setRowsPerPage(event.target.value);
  }, []);

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Fields</Typography>
              </Stack>
            </Stack>

            <FieldsTable
              count={content.length}
              items={contentPage}
              totalsGeneralStatus={totalsGeneralStatus}
              totalsPerformanceStatus={totalsPerformanceStatus}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              selected={[]}
              handleSearch={handleSearch}
              fieldActionStatus={fieldActionStatus}
            />
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default Page;
