import { Box, Button, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { createVarietyThunk, updateVarietyThunk } from "../maintainer-slice";

const schema = yup.object().shape({
  name: yup.string().required('Name is required')
});

const UpsertVarietyModal = ({ variety, onClose, open }) => {

  const dispatch = useDispatch();

  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });
  const handleUpsert = (data) => {
    if (variety) {
      dispatch(updateVarietyThunk({ id: variety.id, name: data.name}));
    } else {
      dispatch(createVarietyThunk({ name: data.name}));
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        {variety ? "Edit" : "Add"} Variety
      </DialogTitle>
    <DialogContent>
      <Box display="flex" flexDirection="column">
     <Controller
        name="name"
        control={control}
        defaultValue={variety?.name || "" }
        render={({ field }) => (
          <TextField
            {...field}
            label="Name"
            variant="outlined"
            margin="normal"
            sx={{ minHeight: 56 }}
            helperText={errors.name?.message}
          />
        )}
      />
      <Button
        onClick={handleSubmit(handleUpsert)}
        variant="contained"
        sx={{ mt: 2 }}
      >
        Save
      </Button>
      </Box>
    </DialogContent>
    </Dialog>
  );
}

UpsertVarietyModal.propTypes = {
  variety: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

export default UpsertVarietyModal;