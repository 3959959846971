import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteOrder,
  fetchAllOrdersWithFields,
  fetchAllOrdersWithFieldsFromUser,
} from "../../services/orders";
import { RootState } from "../../app/store";
import { fetchClients } from "src/services/clients";
import { fetchSpecies } from "src/services/specie";
import { fetchVariety } from "src/services/variety";
import { fetchFieldmans } from "src/services/fieldman";
import { fetchSeasons } from "src/services/season";
import { getProgramDefault } from "src/services/programConfig";
import { stat } from "fs";

export interface OrdersState {
  orders: Array<{}>;
  orderActionStatus: "idle" | "loading" | "failed" | "succeeded";
  status: "idle" | "loading" | "failed";
  clients: Array<{}>;
  clientsStatus: "idle" | "loading" | "failed";
  species: Array<{}>;
  speciesStatus: "idle" | "loading" | "failed";
  variety: Array<{}>;
  varietyStatus: "idle" | "loading" | "failed";
  fieldmans: Array<{}>;
  fieldmansStatus: "idle" | "loading" | "failed";
  seasons: Array<{}>;
  seasonsStatus: "idle" | "loading" | "failed";
  orderSelected: {} | null;
  programDefault: any;
}

const initialState: OrdersState = {
  orders: [],
  status: "idle",
  orderActionStatus: "idle",
  clients: [],
  clientsStatus: "idle",
  species: [],
  speciesStatus: "idle",
  variety: [],
  varietyStatus: "idle",
  fieldmans: [],
  fieldmansStatus: "idle",
  seasons: [],
  seasonsStatus: "idle",
  orderSelected: null,
  programDefault: null,
};

// export const ordersAsync = createAsyncThunk("orders/fetchOrders", async () => {
//   const response = await fetchOrders();
//   return response.data;
// });

export const ordersAsync = createAsyncThunk(
  "orders/fetchOrdersMetadata",
  async (_, { getState }) => {
    const userId = (getState() as RootState).auth.userId;
    const response = await fetchAllOrdersWithFieldsFromUser(userId || 1);
    return response.data;
  }
);

export const clientsAsync = createAsyncThunk(
  "orders/fetchClients",
  async (_, { getState }) => {
    const programId = (getState() as RootState).auth.programId;
    const response = await fetchClients(programId);
    return response.data;
  }
);

export const speciesAsync = createAsyncThunk(
  "orders/fetchSpecies",
  async (_, { getState }) => {
    const programId = (getState() as RootState).auth.programId;
    const response = await fetchSpecies(programId);
    return response.data;
  }
);

export const varietyAsync = createAsyncThunk(
  "orders/fetchVariety",
  async (_, { getState }) => {
    const programId = (getState() as RootState).auth.programId;
    const response = await fetchVariety(programId);
    return response;
  }
);

export const fieldmanAsync = createAsyncThunk(
  "orders/fetchFieldmans",
  async (_, { getState }) => {
    const programId = (getState() as RootState).auth.programId;
    const response = await fetchFieldmans(programId);
    return response;
  }
);

export const seasonsAsync = createAsyncThunk(
  "orders/fetchSeasons",
  async (_, { getState }) => {
    const programId = (getState() as RootState).auth.programId;
    const response = await fetchSeasons(programId);
    return response;
  }
);

export const programDefaultAsync = createAsyncThunk(
  "orders/fetchProgramDefault",
  async (_, { getState }) => {
    const programId = (getState() as RootState).auth.programId;
    const response = await getProgramDefault(programId);
    return response.data;
  }
);

export const deleteOrderAsync = createAsyncThunk(
  "orders/deleteOrder",
  async (orderId: number) => {
    const response = await deleteOrder(orderId);
    return response.data;
  }
);

export const ordersSlice = createSlice({
  name: "slice",
  initialState,
  reducers: {
    setOrderSelected: (state, action) => {
      state.orderSelected = action.payload;
    },
    resetIdleAction: (state) => {
      state.orderActionStatus = "idle";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(ordersAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(ordersAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.orders = action.payload;
      })
      .addCase(ordersAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(clientsAsync.pending, (state) => {
        state.clientsStatus = "loading";
      })
      .addCase(clientsAsync.fulfilled, (state, action) => {
        state.clientsStatus = "idle";
        state.clients = action.payload.map((item: any) => ({
          id: item.id,
          label: item.name,
        }));
      })
      .addCase(clientsAsync.rejected, (state) => {
        state.clientsStatus = "failed";
      })
      .addCase(speciesAsync.pending, (state) => {
        state.speciesStatus = "loading";
      })
      .addCase(speciesAsync.fulfilled, (state, action) => {
        state.speciesStatus = "idle";
        state.species = action.payload.map((item: any) => ({
          id: item.id,
          label: item.name,
        }));
      })
      .addCase(speciesAsync.rejected, (state) => {
        state.speciesStatus = "failed";
      })

      .addCase(varietyAsync.pending, (state) => {
        state.varietyStatus = "loading";
      })
      .addCase(varietyAsync.fulfilled, (state, action) => {
        state.varietyStatus = "idle";
        state.variety = action.payload.map((item) => ({
          id: item.id,
          label: item.name,
        }));
      })
      .addCase(varietyAsync.rejected, (state) => {
        state.varietyStatus = "failed";
      })
      .addCase(fieldmanAsync.pending, (state) => {
        state.fieldmansStatus = "loading";
      })
      .addCase(fieldmanAsync.fulfilled, (state, action) => {
        state.fieldmansStatus = "idle";
        state.fieldmans = action.payload.map((item) => ({
          id: item.id,
          label: item.name,
        }));
      })
      .addCase(fieldmanAsync.rejected, (state) => {
        state.fieldmansStatus = "failed";
      })
      .addCase(seasonsAsync.pending, (state) => {
        state.seasonsStatus = "loading";
      })
      .addCase(seasonsAsync.fulfilled, (state, action) => {
        state.seasonsStatus = "idle";
        state.seasons = action.payload.map((item) => ({
          id: item.id,
          label: item.name,
        }));
      })
      .addCase(seasonsAsync.rejected, (state) => {
        state.seasonsStatus = "failed";
      })

      .addCase(programDefaultAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.programDefault = action.payload;
      })
      .addCase(deleteOrderAsync.pending, (state) => {
        state.orderActionStatus = "loading";
      })
      .addCase(deleteOrderAsync.fulfilled, (state) => {
        state.orderActionStatus = "succeeded";
      })
      .addCase(deleteOrderAsync.rejected, (state) => {
        state.orderActionStatus = "failed";
      });
  },
});

export const { setOrderSelected, resetIdleAction } = ordersSlice.actions;
//SELECTORS
export const selectOrdersState = (state: RootState) => state.orders;
export const selectOrderSelected = (state: RootState) =>
  state.orders.orderSelected;
export const selectProgramDefault = (state: RootState) =>
  state.orders.programDefault;

export default ordersSlice.reducer;
