import api from "./api";

const MANIFOLD_PH_URL = "/manifold/ph";

export const fetchManifoldById = async (id: String) => {
  return await api.get(`${MANIFOLD_PH_URL}/${id}`);
};

export const fetchManifoldDates = async (fieldId: String) => {
  return await api.get(`${MANIFOLD_PH_URL}/dates/${fieldId}`);
};
