import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from "react-redux";
import * as yup from 'yup';
import { createFieldmanThunk } from '../maintainer-slice';
import PropTypes from "prop-types";

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  phoneNumber: yup
    .string()
    .matches(/^56\d{9}$/, 'Invalid phone number')
    .required('Phone is required'),
});

const UpsertFieldmanModal = ({ fieldman, onClose, open }) => {

  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);

  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const handleUpsert = (data) => {
    // if (fieldman) {
    //   dispatch(updateFieldmanThunk({ id: fieldman.id, name: data.name, email: data.email, phone: data.phone}));
    // } else {
    //   dispatch(createFieldmanThunk({ name: data.name, email: data.email, phone: data.phone}));
    // }
    dispatch(createFieldmanThunk({...data, qualityInspector: checked}))

    onClose();
  };
  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      
    >
      <DialogTitle>
        {fieldman ? "Edit" : "Add"} Fieldman
      </DialogTitle>
      <DialogContent sx={{ width: { sm: 500 } }}>
        <Box display="flex" flexDirection="column">
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Name"
                variant="outlined"
                margin="normal"
                sx={{ minHeight: 56 }}
                helperText={errors.name?.message}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Email"
                variant="outlined"
                margin="normal"
                type="email"
                sx={{ minHeight: 56 }}
                helperText={errors.email?.message}
              />
            )}
          />
           <Controller
              name="phoneNumber"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Phone"
                  placeholder="56 9 1234 5678"
                  variant="outlined"
                  margin="normal"
                  sx={{ minHeight: 56 }}
                  helperText={errors.phoneNumber?.message}
                />
              )}
            />
          <Box display="flex" alignItems="center">
           
            <Typography variant="subtitle2" marginLeft={2}>
              Quality inspector
            </Typography>
            <Checkbox
              checked={checked}
              onChange={handleCheckboxChange}
              color="primary"
            />
          </Box>
          <Button onClick={handleSubmit(handleUpsert)} variant="contained" color="success">
            Save
          </Button>

        </Box>
      </DialogContent>
    </Dialog>
  );
};

UpsertFieldmanModal.propTypes = {
  fieldman: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};


export default UpsertFieldmanModal;