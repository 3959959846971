import { useMemo } from "react";

export const useFieldsFilter = (data, search) => {
  return useMemo(() => {
    if (!search || search.trim() === "") {
      return data;
    }
    return data.filter((item) => {
      return (
        item.name?.toLowerCase().includes(search.toLowerCase()) ||
        item.specieName?.toLowerCase().includes(search.toLowerCase()) ||
        item.varietyName?.toLowerCase().includes(search.toLowerCase()) ||
        item.fieldNumber?.toString().includes(search)
      );
    });
  }, [data, search]);
};
