import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  fetchFieldDetailThunk,
  fetchManifoldByIdThunk,
  fetchManifoldDatesThunk,
  selectFieldDetailState,
} from "./field-detail-slice";
import { Box, Container, Grid } from "@mui/material";
import FieldSummary from "./FieldSummary";
import FieldPerformance from "./FieldPerformance";
import FieldStatus from "./FieldStatus";
import FieldPeople from "./FieldPeople";
import FieldGraph from "./FieldGraph";
import FieldManifold from "./FieldManifold";
import { useAppDispatch } from "src/app/hooks";
import { useSelector } from "react-redux";

const FieldDetailScreen = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { fieldData, manifoldDates, manifoldSelected } = useSelector(
    selectFieldDetailState
  );

  useEffect(() => {
    if (id) {
      dispatch(fetchFieldDetailThunk(id));
      dispatch(fetchManifoldDatesThunk(id));
    }
    id && dispatch(fetchFieldDetailThunk(id));
  }, [dispatch, id]);

  useEffect(() => {
    manifoldSelected &&
      dispatch(fetchManifoldByIdThunk(manifoldSelected.id.toString()));
  }, [dispatch, manifoldSelected]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 6,
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={3}>
            <FieldSummary
              fieldName={fieldData?.name || ""}
              fieldNumber={fieldData?.fieldNumber || ""}
              fieldSpecie={fieldData?.specieName || ""}
              fieldVariety={fieldData?.varietyName || ""}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <FieldPeople
              costumer={fieldData?.clientName || ""}
              fieldman={fieldData?.fieldmanName || ""}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <FieldPerformance
              performance={fieldData?.performance || undefined}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <FieldStatus status={fieldData?.generalStatus} />
          </Grid>
          <Grid item xs={12} sm={12} lg={3}>
            <FieldGraph
              chartSeries={[
                fieldData?.totals?.GREEN | 0,
                fieldData?.totals?.YELLOW | 0,
                fieldData?.totals?.RED | 0,
              ]}
              labels={["Good", "Regular", "Bad"]}
              sx={{ height: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={9}>
            <FieldManifold dates={manifoldDates} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FieldDetailScreen;
