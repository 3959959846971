export const POINT_TYPE: { [key: string]: string } = {
  1: "Volunteer",
  2: "Wild",
  3: "Undergrowth",
};

export const ALERT_STATUS_LABELS: { [key: string]: string } = {
  RED: "Bad",
  YELLOW: "Regular",
  GREEN: "Good",
  UNKNOWN: "Unknown",
  default: "Unknown",
};
export const ALERT_STATUS_COLORS: { [key: string]: string } = {
  RED: "error",
  YELLOW: "warning",
  GREEN: "success",
  UNKNOWN: "info",
  default: "info",
};

export const ALERT_STATUS_AVATAR_COLORS: { [key: string]: string } = {
  RED: "error.main",
  YELLOW: "warning.main",
  GREEN: "success.main",
  UNKNOWN: "",
  default: "",
};

export const LEVEL_STATUS_LABELS: { [key: string]: string } = {
  BAD: "Bad",
  GOOD: "Good",
  REGULAR: "Regular",
  UNKNOWN: "Unknown",
  default: "Unknown",
};
export const LEVEL_STATUS_COLORS: { [key: string]: string } = {
  BAD: "error",
  REGULAR: "warning",
  GOOD: "success",
  UNKNOWN: "info",
  default: "info",
};
