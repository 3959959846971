import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/zoom";
import classes from "./SwiperImg.module.css";
import ImageHolder from "../ImageHolder/ImageHolder";

interface SwiperImage {
  src: string;
  title: string;
  landscape?: boolean;
  date?: string;
}
interface SwiperProps {
  images: SwiperImage[];
}
const SwiperImg = ({ images }: SwiperProps) => {
  return (
    <Swiper
      navigation={true}
      modules={[Navigation]}
      className={classes.mySwiper}
      slidesPerView={"auto"}
    >
      {images?.map((image, idx) => (
        <SwiperSlide zoom={true} key={idx}>
          <div className={classes.swiperContent}>
            <ImageHolder
              src={image.src}
              title={image.title}
              landscape={image.landscape}
              date={image.date}
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
export default SwiperImg;
