
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Card, InputAdornment, OutlinedInput, SvgIcon } from '@mui/material';

const SearchBarMaintainer = (props) => {
  const { name, onSearch, onCreate } = props;

  return(
  <Card sx={{ p: 2, display:"flex",flexDirection: "row", justifyContent: "space-between" }}>
    <OutlinedInput
      defaultValue=""
      fullWidth
      placeholder={`Search ${name}`}
      onChange={onSearch}
      startAdornment={(
        <InputAdornment position="start">
          <SvgIcon
            color="action"
            fontSize="small"
          >
            <SearchIcon />
          </SvgIcon>
        </InputAdornment>
      )}
      sx={{ maxWidth: 500 }}
    />
    <Button variant="contained" onClick={onCreate} sx={{ minHeight: 46, marginLeft: 2 }} color="success">
          {`Create ${name}`}
        </Button>
  </Card>)
};
SearchBarMaintainer.propTypes = {
  name: PropTypes.string,
  onSearch: PropTypes.func,
  onCreate: PropTypes.func,
};
export default SearchBarMaintainer;