import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { fetchFieldById } from "src/services/field";
import { fetchInspectionsByField } from "src/services/inspection";
import { fetchOrderWithFields } from "src/services/orders";
export interface InspectionReportState {
  order: any | null;
  field: any | null;
  inspections: [] | null;
  statusField: "idle" | "loading" | "failed";
  statusInspections: "idle" | "loading" | "failed";
  statusOrder: "idle" | "loading" | "failed";
}

const initialState: InspectionReportState = {
  order: null,
  field: null,
  inspections: [],
  statusField: "loading",
  statusInspections: "loading",
  statusOrder: "loading",
};

export const getOrderThunk = createAsyncThunk(
  "inspection/fetchOrder",
  async (id: number) => {
    const response = await fetchOrderWithFields(id);
    return response.data;
  }
);

export const getFieldThunk = createAsyncThunk(
  "inspection/fetchField",
  async (id: number) => {
    const response = await fetchFieldById(id);
    return response.data;
  }
);

export const getInspectionsThunk = createAsyncThunk(
  "inspection/fetchInspections",
  async (id: number) => {
    const response = await fetchInspectionsByField(id);
    return response.data;
  }
);

export const inspectionSlice = createSlice({
  name: "inspectionlice",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setField: (state, action) => {
      state.field = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getOrderThunk.pending, (state) => {
      state.statusOrder = "loading";
    });
    builder.addCase(getOrderThunk.fulfilled, (state, action) => {
      state.statusOrder = "idle";
      state.order = action.payload;
    });
    builder.addCase(getOrderThunk.rejected, (state, action) => {
      state.statusOrder = "failed";
    });

    builder.addCase(getFieldThunk.pending, (state) => {
      state.statusField = "loading";
    });
    builder.addCase(getFieldThunk.fulfilled, (state, action) => {
      state.statusField = "idle";
      state.field = action.payload;
    });
    builder.addCase(getFieldThunk.rejected, (state, action) => {
      state.statusField = "failed";
    });
    builder.addCase(getInspectionsThunk.pending, (state) => {
      state.statusInspections = "loading";
    });
    builder.addCase(getInspectionsThunk.fulfilled, (state, action) => {
      state.statusInspections = "idle";
      state.inspections = action.payload;
    });
    builder.addCase(getInspectionsThunk.rejected, (state, action) => {
      state.statusInspections = "failed";
    });
  },
});

export const { setField } = inspectionSlice.actions;

export const selectInspectionState = (state: RootState) => state.inspection;

export default inspectionSlice.reducer;
