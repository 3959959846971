import { useMemo } from "react";

export const useFilter = (data, search) => {
  return useMemo(() => {
    if (!search || search.trim() === "") {
      return data;
    }
    return data.filter((item) => {
      return item.name.toLowerCase().includes(search.toLowerCase());
    });
  }, [data, search]);
};
