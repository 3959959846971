export const BOOK_FORM_PM = [
  {
    title: "Surface",
    size: 300,
    values: [
      { label: "Surface Contracted", key: "surfaceContracted", type: "number" },
      { label: "Surface GPS", key: "surfaceGPS", type: "number" },
    ],
  },
  {
    title: "Planting",
    size: 500,
    values: [
      { label: "Planting Female", key: "plantingFemale", type: "date" },
      { label: "Planting Male 1", key: "plantingMale1", type: "date" },
      { label: "Planting Male 2", key: "plantingMale2", type: "date" },
      { label: "Planting Male 3", key: "plantingMale3", type: "date" },
    ],
  },
  {
    title: "Population",
    size: 500,
    values: [
      { label: "Population Female", key: "populationFemale", type: "number" },
      { label: "Population Male 1", key: "populationMale1", type: "number" },
      { label: "Population Male 2", key: "populationMale2", type: "number" },
      { label: "Population Male 3", key: "populationMale3", type: "number" },
    ],
  },
  {
    title: "Estimated flowering",
    size: 200,
    values: [
      { label: "Estimated flowering", key: "estimatedFlowering", type: "date" },
    ],
  },
  {
    title: "Detasseling",
    size: 200,
    values: [{ label: "Detasseling", key: "detasseling", type: "date" }],
  },
  {
    title: "Flowering 10%",
    size: 500,
    values: [
      { label: "Female", key: "flowering10Female", type: "date" },
      { label: "Male 1", key: "flowering10Male1", type: "date" },
      { label: "Male 2", key: "flowering10Male2", type: "date" },
      { label: "Male 3", key: "flowering10Male3", type: "date" },
    ],
  },

  {
    title: "Flowering 50%",
    size: 500,
    values: [
      { label: "Female", key: "flowering50Female", type: "date" },
      { label: "Male 1", key: "flowering50Male1", type: "date" },
      { label: "Male 2", key: "flowering50Male2", type: "date" },
      { label: "Male 3", key: "flowering50Male3", type: "date" },
    ],
  },
  {
    title: "Flowering 100%",
    size: 500,
    values: [
      { label: "Female", key: "flowering100Female", type: "date" },
      { label: "Male 1", key: "flowering100Male1", type: "date" },
      { label: "Male 2", key: "flowering100Male2", type: "date" },
      { label: "Male 3", key: "flowering100Male3", type: "date" },
    ],
  },
  {
    title: "Off type pull percentage",
    size: 200,
    values: [
      { label: "Off type", key: "offTypePullPercentage", type: "number" },
    ],
  },
  {
    title: "Dessecant Spraying",
    size: 200,
    values: [
      { label: "Dessecant Spraying", key: "dessecantSpraying", type: "text" },
    ],
  },
  {
    title: "Estimated Yield",
    size: 200,
    values: [{ label: "Estimated Yield", key: "estimatedYield", type: "text" }],
  },
  {
    title: "Harvest",
    size: 300,
    values: [
      { label: "Harvest Type", key: "harvestType", type: "text" },
      { label: "Harvest date", key: "harvest", type: "date" },
    ],
  },
  {
    title: "Yield",
    size: 300,
    values: [
      { label: "Yield kg", key: "yieldWeightKg", type: "number" },
      { label: "Yield lb", key: "yieldWeightLb", type: "number" },
    ],
  },
  {
    title: "Warm Percentage",
    size: 200,
    values: [
      { label: "Warm Percentage", key: "warmPercentage", type: "number" },
    ],
  },
  {
    title: "Seed Weight",
    size: 200,
    values: [{ label: "Seed Weight", key: "seedWeight", type: "number" }],
  },
  {
    title: "Cold Test Percentage",
    size: 200,
    values: [
      {
        label: "Cold Test Percentage",
        key: "coldTestPercentage",
        type: "number",
      },
    ],
  },
];

export const BOOK_FORM_PH = [
  {
    title: "Surface",
    size: 200,
    values: [{ label: "Surface", key: "surface", type: "number" }],
  },
  {
    title: "Sowing",
    size: 400,
    values: [
      { label: "Sowing Female", key: "sowingFemale", type: "date" },
      { label: "Sowing Male 1", key: "sowingMale1", type: "date" },
      { label: "Sowing Male 2", key: "sowingMale2", type: "date" },
      { label: "Sowing Male 3", key: "sowingMale3", type: "date" },
    ],
  },
  {
    title: "Transplanting",
    size: 400,
    values: [
      {
        label: "Transplanting Female",
        key: "transplantingFemale",
        type: "date",
      },
      {
        label: "Transplanting Male 1",
        key: "transplantingMale1",
        type: "date",
      },
      {
        label: "Transplanting Male 2",
        key: "transplantingMale2",
        type: "date",
      },
      {
        label: "Transplanting Male 3",
        key: "transplantingMale3",
        type: "date",
      },
    ],
  },
  {
    title: "Commercial",
    size: 400,
    values: [
      { label: "Commercial Female", key: "commercialFemale", type: "date" },
      { label: "Commercial Male 1", key: "commercialMale1", type: "date" },
      { label: "Commercial Male 2", key: "commercialMale2", type: "date" },
      { label: "Commercial Male 3", key: "commercialMale3", type: "date" },
    ],
  },
  {
    title: "Cutting",
    size: 400,
    values: [
      { label: "Cut Female", key: "cutFemale", type: "date" },
      { label: "Cut Male 1", key: "cutMale1", type: "date" },
      { label: "Cut Male 2", key: "cutMale2", type: "date" },
      { label: "Cut Male 3", key: "cutMale3", type: "date" },
    ],
  },
  {
    title: "Induction",
    size: 400,
    values: [
      { label: "Induction Female", key: "inductionFemale", type: "date" },
      { label: "Induction Male 1", key: "inductionMale1", type: "date" },
      { label: "Induction Male 2", key: "inductionMale2", type: "date" },
      { label: "Induction Male 3", key: "inductionMale3", type: "date" },
    ],
  },
  {
    title: "Bolting",
    size: 400,
    values: [
      { label: "Bolting Female", key: "boltingFemale", type: "date" },
      { label: "Bolting Male 1", key: "boltingMale1", type: "date" },
      { label: "Bolting Male 2", key: "boltingMale2", type: "date" },
      { label: "Bolting Male 3", key: "boltingMale3", type: "date" },
    ],
  },
  {
    title: "Flowering 1%",
    size: 400,
    values: [
      { label: "Flowering 1 Female", key: "flowering1Female", type: "date" },
      { label: "Flowering 1 Male 1", key: "flowering1Male1", type: "date" },
      { label: "Flowering 1 Male 2", key: "flowering1Male2", type: "date" },
      { label: "Flowering 1 Male 3", key: "flowering1Male3", type: "date" },
    ],
  },
  {
    title: "Flowering 50%",
    size: 400,
    values: [
      { label: "Flowering 50 Female", key: "flowering50Female", type: "date" },
      { label: "Flowering 50 Male 1", key: "flowering50Male1", type: "date" },
      { label: "Flowering 50 Male 2", key: "flowering50Male2", type: "date" },
      { label: "Flowering 50 Male 3", key: "flowering50Male3", type: "date" },
    ],
  },
  {
    title: "Flowering -1%",
    size: 300,
    values: [
      {
        label: "Flowering 100 Female",
        key: "flowering100Female",
        type: "date",
      },
      { label: "Flowering 100 Male 1", key: "flowering100Male1", type: "date" },
      { label: "Flowering 100 Male 2", key: "flowering100Male2", type: "date" },
      { label: "Flowering 100 Male 3", key: "flowering100Male3", type: "date" },
    ],
  },
  {
    title: "Nicking 1",
    size: 400,
    values: [
      { label: "Nicking 1 Female", key: "nicking1Female", type: "date" },
      { label: "Nicking 1 Male 1", key: "nicking1Male1", type: "date" },
      { label: "Nicking 1 Male 2", key: "nicking1Male2", type: "date" },
      { label: "Nicking 1 Male 3", key: "nicking1Male3", type: "date" },
    ],
  },
  {
    title: "Nicking 2",
    size: 400,
    values: [
      { label: "Nicking 2 Female", key: "nicking2Female", type: "date" },
      { label: "Nicking 2 Male 1", key: "nicking2Male1", type: "date" },
      { label: "Nicking 2 Male 2", key: "nicking2Male2", type: "date" },
      { label: "Nicking 2 Male 3", key: "nicking2Male3", type: "date" },
    ],
  },
  {
    title: "Nicking 3",
    size: 400,
    values: [
      { label: "Nicking 3 Female", key: "nicking3Female", type: "date" },
      { label: "Nicking 3 Male 1", key: "nicking3Male1", type: "date" },
      { label: "Nicking 3 Male 2", key: "nicking3Male2", type: "date" },
      { label: "Nicking 3 Male 3", key: "nicking3Male3", type: "date" },
    ],
  },
  {
    title: "Bee",
    size: 200,
    values: [
      { label: "Bee", key: "bee", type: "date" },
      { label: "Bee Audit", key: "beeAudit", type: "date" },
    ],
  },
  {
    title: "Male Elimination",
    size: 300,
    values: [
      { label: "Male Elimination 1", key: "maleElimination1", type: "date" },
      { label: "Male Elimination 2", key: "maleElimination2", type: "date" },
      { label: "Male Elimination 3", key: "maleElimination3", type: "date" },
    ],
  },
  {
    title: "Harvest",
    size: 400,
    values: [
      { label: "Harvest 1", key: "harvest1", type: "date" },
      { label: "Harvest 2", key: "harvest2", type: "date" },
      { label: "Harvest 3", key: "harvest3", type: "date" },
      { label: "Harvest 4", key: "harvest4", type: "date" },
    ],
  },
];

export const BOOK_FORM_PH_WEB = [
  {
    title: "Surface",
    size: 1,
    values: [{ label: "Surface", key: "surface", type: "number" }],
  },
  {
    title: "Sowing",
    size: 4,
    values: [
      { label: "Female", key: "sowingFemale", type: "date" },
      { label: "Male 1", key: "sowingMale1", type: "date" },
      { label: "Male 2", key: "sowingMale2", type: "date" },
      { label: "Male 3", key: "sowingMale3", type: "date" },
    ],
  },
  {
    title: "Transplanting",
    size: 4,
    values: [
      {
        label: "Female",
        key: "transplantingFemale",
        type: "date",
      },
      {
        label: "Male 1",
        key: "transplantingMale1",
        type: "date",
      },
      {
        label: "Male 2",
        key: "transplantingMale2",
        type: "date",
      },
      {
        label: "Male 3",
        key: "transplantingMale3",
        type: "date",
      },
    ],
  },
  {
    title: "Commercial",
    size: 4,
    values: [
      { label: "Female", key: "commercialFemale", type: "date" },
      { label: "Male 1", key: "commercialMale1", type: "date" },
      { label: "Male 2", key: "commercialMale2", type: "date" },
      { label: "Male 3", key: "commercialMale3", type: "date" },
    ],
  },
  {
    title: "Cutting",
    size: 4,
    values: [
      { label: "Female", key: "cutFemale", type: "date" },
      { label: "Male 1", key: "cutMale1", type: "date" },
      { label: "Male 2", key: "cutMale2", type: "date" },
      { label: "Male 3", key: "cutMale3", type: "date" },
    ],
  },
  {
    title: "Induction",
    size: 4,
    values: [
      { label: "Female", key: "inductionFemale", type: "date" },
      { label: "Male 1", key: "inductionMale1", type: "date" },
      { label: "Male 2", key: "inductionMale2", type: "date" },
      { label: "Male 3", key: "inductionMale3", type: "date" },
    ],
  },
  {
    title: "Bolting",
    size: 4,
    values: [
      { label: "Female", key: "boltingFemale", type: "date" },
      { label: "Male 1", key: "boltingMale1", type: "date" },
      { label: "Male 2", key: "boltingMale2", type: "date" },
      { label: "Male 3", key: "boltingMale3", type: "date" },
    ],
  },
  {
    title: "Flowering 1%",
    size: 4,
    values: [
      { label: "Female", key: "flowering1Female", type: "date" },
      { label: "Male 1", key: "flowering1Male1", type: "date" },
      { label: "Male 2", key: "flowering1Male2", type: "date" },
      { label: "Male 3", key: "flowering1Male3", type: "date" },
    ],
  },
  {
    title: "Flowering 50%",
    size: 4,
    values: [
      { label: "Female", key: "flowering50Female", type: "date" },
      { label: "Male 1", key: "flowering50Male1", type: "date" },
      { label: "Male 2", key: "flowering50Male2", type: "date" },
      { label: "Male 3", key: "flowering50Male3", type: "date" },
    ],
  },
  {
    title: "Flowering -1%",
    size: 4,
    values: [
      {
        label: "Female",
        key: "flowering100Female",
        type: "date",
      },
      { label: "Male 1", key: "flowering100Male1", type: "date" },
      { label: "Male 2", key: "flowering100Male2", type: "date" },
      { label: "Male 3", key: "flowering100Male3", type: "date" },
    ],
  },
  {
    title: "Nicking 1",
    size: 4,
    values: [
      { label: "Female", key: "nicking1Female", type: "date" },
      { label: "Male 1", key: "nicking1Male1", type: "date" },
      { label: "Male 2", key: "nicking1Male2", type: "date" },
      { label: "Male 3", key: "nicking1Male3", type: "date" },
    ],
  },
  {
    title: "Nicking 2",
    size: 4,
    values: [
      { label: "Female", key: "nicking2Female", type: "date" },
      { label: "Male 1", key: "nicking2Male1", type: "date" },
      { label: "Male 2", key: "nicking2Male2", type: "date" },
      { label: "Male 3", key: "nicking2Male3", type: "date" },
    ],
  },
  {
    title: "Nicking 3",
    size: 4,
    values: [
      { label: "Female", key: "nicking3Female", type: "date" },
      { label: "Male 1", key: "nicking3Male1", type: "date" },
      { label: "Male 2", key: "nicking3Male2", type: "date" },
      { label: "Male 3", key: "nicking3Male3", type: "date" },
    ],
  },
  {
    title: "Bee",
    size: 2,
    values: [
      { label: "Bee", key: "bee", type: "date" },
      { label: "Bee Audit", key: "beeAudit", type: "date" },
    ],
  },
  {
    title: "Male Elimination",
    size: 3,
    values: [
      { label: "Male 1", key: "maleElimination1", type: "date" },
      { label: "Male 2", key: "maleElimination2", type: "date" },
      { label: "Male 3", key: "maleElimination3", type: "date" },
    ],
  },
  {
    title: "Harvest",
    size: 4,
    values: [
      { label: "Harvest 1", key: "harvest1", type: "date" },
      { label: "Harvest 2", key: "harvest2", type: "date" },
      { label: "Harvest 3", key: "harvest3", type: "date" },
      { label: "Harvest 4", key: "harvest4", type: "date" },
    ],
  },
];
