import { useState, useEffect, useCallback } from "react";
import {
  Typography,
  TextField,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
  Box,
  ToggleButton, ToggleButtonGroup, Autocomplete,
} from "@mui/material";
import { AddCircleOutline, Delete } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { createEmailConfigThunk, deleteEmailConfigThunk, getEmailConfigsByProgramThunk, programDefaultThunk, selectProgramConfigState, updateSeasonDefaultThunk } from "./ProgramConfigSlice";
import { isValidEmail } from "src/utils/validations";
import SeasonDialog from "./SeasonDialog";
import useFetcher from "src/hooks/Fetcher/useFetcher";
import { fetchSeasons } from "src/services/season";

const ProgramConfigScreen = () => {
  const dispatch = useDispatch();
  const {content, isLoading, programDefault} = useSelector(selectProgramConfigState)
  const [contentFiltered, setContentFiltered] = useState([])
  const [email, setEmail] = useState("");
  const [optionSelected, setOptionSelected] = useState("manifold")
  const [openSeasonDialog, setOpenSeasonDialog] = useState(false)
  const [seasonSelected, setSeasonSelected] = useState(null)
  const emailValid = isValidEmail(email)
  const programId = useSelector((state) => state.auth.programId);
  const { data: seasons } = useFetcher(
    useCallback(() => fetchSeasons(programId ), [programId])
  );
  const fetchElements = useCallback(() => {
    dispatch(getEmailConfigsByProgramThunk())
    dispatch(programDefaultThunk())
  }, [dispatch]) 

  useEffect(() => {
    fetchElements();
  }, [fetchElements]);

  const filterElements = useCallback((newValue) => {
    switch (newValue) {
      case "manifold":
        setContentFiltered (content?.filter(element => element.reportType === "MANIFOLD"))
        break;
      case "quality":
        setContentFiltered ( content?.filter(element => element.reportType === "QUALITY"))
        break;
      case "alert":
      setContentFiltered ( content?.filter(element => element.reportType === "ALERT"))
      break;
      default:
        break;
    }
  }, [content])

  useEffect(() => {
    filterElements(optionSelected)
  } ,[optionSelected, content, filterElements])


  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleCreateElement = () => {
    switch (optionSelected) {
      case "manifold":
        if(!emailValid) return alert("Email is not valid")
        dispatch(createEmailConfigThunk({ email, reportType: "MANIFOLD"}))
        setEmail("")
        break;
        case "quality":
          if(!emailValid) return alert("Email is not valid")
          dispatch(createEmailConfigThunk({ email, reportType: "QUALITY"}))
          setEmail("")
          break;
          case "alert":
            if(!emailValid) return alert("Email is not valid")
            dispatch(createEmailConfigThunk({ email, reportType: "ALERT"}))
            setEmail("")
            break;
      default:
        break;
    }
  
  };


  const handleDeleteElement = (id) => {
    dispatch(deleteEmailConfigThunk(id))
  };


  const handleToggle = (event,
    newValue) => {
    setOptionSelected(newValue)
  };

  const handleOpenSeasonDialog = () => {
    setOpenSeasonDialog(true)
  }

  const handleUpdateDefaultSeason = () => {
    if(seasonSelected?.id) {
    dispatch(updateSeasonDefaultThunk(seasonSelected.id))
    }

  }

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom>
        Configurations
      </Typography>
      <Box>
      <Box display="flex"  alignItems="center">
        <Typography variant="h6" paddingY={2}>
          Season configurations
        </Typography>
        
        <IconButton color="primary" aria-label="add" onClick={handleOpenSeasonDialog}>
          <AddCircleOutline />
        </IconButton>
      </Box>
      <Typography variant="subtitle1" paddingY={2}>
          Current season: {programDefault?.season?.name}
        </Typography>
      
      {!!seasons && (
        <Box display="flex" alignItems="center">
          <Autocomplete
            options={seasons}
            getOptionLabel={(option) => option.name}
            sx={{ width: 300, marginRight: 2}}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Default season"
                variant="outlined"
              />
            )}
            onChange={(event, newValue) => {
              setSeasonSelected(newValue);
            }}
          />
          <Button variant="contained" color="success" onClick={handleUpdateDefaultSeason}>
            Set current season
          </Button>
        </Box>
      )}
      </Box>
      <Typography variant="h6" paddingY={4} >
        Email configurations
      </Typography>
      <Box>
      <ToggleButtonGroup
        value={optionSelected}
        exclusive
        onChange={handleToggle}
        aria-label="Element options"
      >
        <ToggleButton value="manifold" aria-label="Option 1">
          Manifold
        </ToggleButton>
        <ToggleButton value="quality" aria-label="Option 2">
          Quality
        </ToggleButton>
        <ToggleButton value="alert" aria-label="Option 3">
          Alert
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>

      <Box display="flex" alignItems="center">
       <TextField

          label="Email"
          value={email}
          onChange={handleEmailChange}
          variant="outlined"
          margin="normal"
          type="email"
          sx={{ minHeight: 56 , marginLeft: 2}}
          error={!emailValid}
          
        />
        <Button variant="contained" onClick={handleCreateElement} sx={{ minHeight: 46, marginLeft: 2 }} color="success" disabled={isLoading}>
          {`Create ${optionSelected}`}
        </Button>
      </Box>
      <TableContainer component={Paper} style={{ marginTop: "16px" }}>
        <Table>
          <TableHead>
            <TableRow>
      
              <TableCell style={{ fontSize: 16 }}>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

           {contentFiltered && contentFiltered.map((element) => (
              <TableRow key={element.id}>
                <TableCell style={{ fontSize: 12 }}>{element.email}</TableCell>
                <TableCell>
                 
                  <IconButton onClick={() => handleDeleteElement(element.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            
          </TableBody>
        </Table>
      </TableContainer>
            <SeasonDialog open={openSeasonDialog} onClose={() => {setOpenSeasonDialog(false)}} />
    </Box>
  );
};

export default ProgramConfigScreen;
