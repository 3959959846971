
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import { Card, InputAdornment, OutlinedInput, SvgIcon } from '@mui/material';

const SearchBarOrder = (props) => {
  const { name, onSearch } = props;

  return(
  <Card sx={{ p: 2, display:"flex",flexDirection: "row", justifyContent: "space-between" }}>
    <OutlinedInput
      defaultValue=""
      fullWidth
      placeholder={`Search ${name}`}
      onChange={onSearch}
      startAdornment={(
        <InputAdornment position="start">
          <SvgIcon
            color="action"
            fontSize="small"
          >
            <SearchIcon />
          </SvgIcon>
        </InputAdornment>
      )}
      sx={{ maxWidth: 500 }}
    />
  </Card>)
};
SearchBarOrder.propTypes = {
  name: PropTypes.string,
  onSearch: PropTypes.func,
};
export default SearchBarOrder;