import Screen from "../../components/Screen/Screen";
import Title from "../../components/Typography/Title";
import Text from "../../components/Typography/Text";
import { Box } from "@mui/system";

const SupportScreen = () => {
  return (
    <Screen padding="m">
      <Box sx={{ flexGrow: 1, alignItems: "center", textAlign: "center" }}>
        <Title color="black" size="xl">
          ZEED APP SUPPORT
        </Title>
        <Box sx={{ flexGrow: 1, marginInline: "20%", marginTop: "20px" }}>
          <Text size="xl" color="grey3">
            If you have any problem or concern with the usage of the Application
            Zeed, please feel free to reach out to our support at
            oscar.cerda.zu@gmail.com
          </Text>
        </Box>
      </Box>
    </Screen>
  );
};

export default SupportScreen;
