import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "src/app/store";
import { LoginResponse } from "src/models/response/UserLogin";
import { login, refreshToken } from "../../services/auth"; // Assuming you have an API module with login functionality

interface AuthState {
  user: string | null;
  userId: number | null;
  programId: number | 1;
  programName: string | null;
  token: string | null;
  refreshToken: string | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: AuthState = {
  user: null,
  userId: null,
  programId: 1,
  programName: null,
  token: null,
  refreshToken: null,
  isLoading: false,
  error: null,
};

export const loginThunk = createAsyncThunk(
  "auth/login",
  async (credentials: { username: string; password: string }) => {
    const response: LoginResponse = await login(credentials);

    const { jwt, jwtRefresh, id, username, roles, program } = response;
    saveTolocalStorage(jwt, jwtRefresh, id);
    return { jwt, jwtRefresh, id, username, roles, program };
  }
);

export const refreshThunk = createAsyncThunk(
  "auth/refresh",
  async (tokenLocal: string) => {
    const response: any = await refreshToken(tokenLocal);
    const { jwt, jwtRefresh, id, username, roles, program } = response;
    saveTolocalStorage(jwt, jwtRefresh, id);
    return { jwt, jwtRefresh, id, username, roles, program };
  }
);

const saveTolocalStorage = (
  token: string,
  refreshToken: string,
  userId: number
) => {
  localStorage.setItem("user", userId.toString());
  localStorage.setItem("token", token);
  localStorage.setItem("refreshToken", refreshToken);
  const currentDate = new Date();
  currentDate.setHours(currentDate.getHours() + 1);
  localStorage.setItem("expirationDate", currentDate.toISOString());
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginUser: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.refreshToken = action.payload.refresh_token;
      state.isLoading = false;
      state.error = null;
    },
    logOut: (state, action) => {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("expirationDate");

      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginThunk.fulfilled, (state, action) => {
        state.user = action.payload.username;
        state.userId = action.payload.id;
        state.token = action.payload.jwt;
        state.refreshToken = action.payload.jwtRefresh;
        if (action.payload.program) {
          state.programId = action.payload.program.id;
          state.programName = action.payload.program.name;
        }
        state.isLoading = false;
        state.error = null;
      })
      .addCase(loginThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(refreshThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(refreshThunk.fulfilled, (state, action) => {
        state.user = action.payload.username;
        state.userId = action.payload.id;
        state.token = action.payload.jwt;
        state.refreshToken = action.payload.jwtRefresh;
        if (action.payload.program) {
          state.programId = action.payload.program.id;
          state.programName = action.payload.program.name;
        }
        state.isLoading = false;
        state.error = null;
      })
      .addCase(refreshThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});
export const { loginUser, logOut } = authSlice.actions;
//SELECTORS
export const selectAuthState = (state: RootState) => state.auth;
export const selectAuthProgramName = (state: RootState) =>
  state.auth.programName;
export default authSlice.reducer;
