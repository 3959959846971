import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import classes from "./InspectionReportScreen.module.css";
import Screen from "../../components/Screen/Screen";
import Map from "../../components/Map/Map";
import Marker from "../../components/Map/Marker";
import { GOOGLE_MAPS_KEY } from "src/config/constants";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { useParams } from "react-router-dom";
import {
  getFieldThunk,
  getInspectionsThunk,
  getOrderThunk,
  selectInspectionState,
  setField,
} from "./InspectionReportSlice";
import { IField } from "src/models/interfaces/order";
import Title from "../../components/Typography/Title";
import { POINT_TYPE } from "src/constants/dictionary";
import Text from "../../components/Typography/Text";
import SwiperImg from "../../components/SwiperImg/SwiperImg";
import { set } from "react-hook-form";

const InspectionReportScreen = () => {
  const [zoom, setZoom] = React.useState(17); // initial zoom
  const [center, setCenter] = React.useState<google.maps.LatLngLiteral>({
    lat: -33.4478849,
    lng: -70.641088,
  });
  const [inspectionSelected, setInspectionSelected] = useState<any>(null);
  const [images, setImages] = useState<any>(null);
  const dispatch = useAppDispatch();
  const {
    order,
    field,
    inspections,
    statusField,
    statusInspections,
    statusOrder,
  } = useAppSelector(selectInspectionState);
  let { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(getOrderThunk(Number(id)));
      // dispatch(getInspectionsThunk(Number(id)));
    }
  }, [dispatch, id]);

  const clickField = (field: any) => () => {
    dispatch(setField(field));
    dispatch(getInspectionsThunk(Number(field.id)));
    setCenter({ lat: field.lat, lng: field.lng });
  };
  const clickInspection = (inspection: any) => () => {
    setInspectionSelected(inspection);
    if (inspection) {
      const arrImg = [];
      if (inspection.wildInteriorPictureUrl) {
        arrImg.push({ src: inspection.wildInteriorPictureUrl });
      }
      if (inspection.wildPerimeterPictureUrl) {
        arrImg.push({ src: inspection.wildPerimeterPictureUrl });
      }
      if (inspection.undergrowthInteriorPictureUrl) {
        arrImg.push({ src: inspection.undergrowthInteriorPictureUrl });
      }
      if (inspection.undergrowthPerimeterPictureUrl) {
        arrImg.push({ src: inspection.undergrowthPerimeterPictureUrl });
      }
      if (inspection.outlierInteriorPictureUrl) {
        arrImg.push({ src: inspection.outlierInteriorPictureUrl });
      }
      if (inspection.outlierPerimeterPictureUrl) {
        arrImg.push({ src: inspection.outlierPerimeterPictureUrl });
      }
      if (inspection.volunteerInteriorPictureUrl) {
        arrImg.push({ src: inspection.volunteerInteriorPictureUrl });
      }
      if (inspection.volunteerPerimeterPictureUrl) {
        arrImg.push({ src: inspection.volunteerPerimeterPictureUrl });
      }
      if (inspection.commercialInteriorPictureUrl) {
        arrImg.push({ src: inspection.commercialInteriorPictureUrl });
      }
      if (inspection.commercialPerimeterPictureUrl) {
        arrImg.push({ src: inspection.commercialPerimeterPictureUrl });
      }

      setImages(arrImg);
    }
  };
  const getColor = (type: string): string => {
    switch (type) {
      case "VOLUNTEER":
        return "yellow1";
      case "WILD":
        return "red1";
      case "UNDERGROWTH":
        return "blue3";
      default:
        return "blue3";
    }
  };
  const getColorMarker = (type: string): string => {
    switch (type) {
      case "VOLUNTEER":
        return "yellow";
      case "WILD":
        return "red";
      case "UNDERGROWTH":
        return "blue";
      default:
        return "blue";
    }
  };
  const getLevel = (level: number | null): string => {
    switch (level) {
      case 1:
        return "Low";
      case 2:
        return "Medium";
      case 3:
        return "High";
      default:
        return "-";
    }
  };

  return (
    <Screen padding="m">
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid xs={6}>
            <Title color="black" size="xl">
              Inspection Reports
            </Title>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid xs={6}>
                <Paper className={classes.inspectionPaper}>
                  <Title color="black">Fields</Title>

                  <List component="nav">
                    {statusOrder === "idle" &&
                      order?.fields?.map((item: any) => (
                        <ListItem
                          key={item.id}
                          onClick={clickField(item)} // Handle click event
                          className={`${classes.listItem} ${
                            item.id === field?.id ? classes.listItemActive : ""
                          }`}
                        >
                          <ListItemText
                            primary={`${item.grower.name} - ${item.name} - ${item.fieldNumber} `}
                          />
                        </ListItem>
                      ))}
                  </List>
                </Paper>
              </Grid>
              <Grid xs={6}>
                <Paper className={classes.inspectionPaper}>
                  <Title color="black">Reports</Title>
                  <List component="nav">
                    {inspections?.map((item: any, index) => (
                      <ListItem
                        key={index}
                        onClick={clickInspection(item)} // Handle click event
                        className={`${classes.listItem} ${
                          item.id === inspectionSelected?.id
                            ? classes.listItemActive
                            : ""
                        }`}
                      >
                        <ListItemText
                          primary={new Date(
                            item.createdAt
                          ).toLocaleDateString()}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              </Grid>
              <Grid xs={12}>
                <Paper className={classes.inspectionPaper}>
                  <Title color="black">Report detail</Title>

                  <Text size="s" color="black">
                    wild interior: {getLevel(inspectionSelected?.wildInterior)}
                  </Text>
                  <Text size="s" color="black">
                    wild perimeter:{" "}
                    {getLevel(inspectionSelected?.wildPerimeter)}
                  </Text>
                  <Text size="s" color="black">
                    undergrowth interior:{" "}
                    {getLevel(inspectionSelected?.undergrowthInterior)}
                  </Text>
                  <Text size="s" color="black">
                    undergrowth perimeter:{" "}
                    {getLevel(inspectionSelected?.undergrowthPerimeter)}
                  </Text>

                  <Text size="s" color="black">
                    outlier interior:{" "}
                    {getLevel(inspectionSelected?.outlierInterior)}
                  </Text>
                  <Text size="s" color="black">
                    outlier perimeter:{" "}
                    {getLevel(inspectionSelected?.outlierPerimeter)}
                  </Text>
                  <Text size="s" color="black">
                    wild interior comment:{" "}
                    {getLevel(inspectionSelected?.wildInteriorComment)}
                  </Text>
                  <Text size="s" color="black">
                    wild perimeter comment:{" "}
                    {getLevel(inspectionSelected?.wildPerimeterComment)}
                  </Text>
                </Paper>
              </Grid>
              <Grid xs={12}>
                <Paper sx={{ padding: 2 }}>
                  <Title>Pictures</Title>
                  <SwiperImg images={images} />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={6}>
            <Title color="black" size="xl">
              Map viewer
            </Title>
            <Wrapper apiKey="">
              <Map
                center={center}
                // onClick={onClick}
                // onIdle={onIdle}
                zoom={zoom}
                style={{ flexGrow: "1", height: "400px", width: "90%" }}
              >
                {inspectionSelected &&
                  inspectionSelected?.points?.map(
                    (point: any, index: number) => (
                      <Marker
                        key={index}
                        position={{
                          lat: point.lat,
                          lng: point.lng,
                        }}
                        icon={{
                          path: google.maps.SymbolPath.CIRCLE,
                          strokeColor: getColorMarker(point.pointType),
                          scale: 3,
                        }}
                      />
                    )
                  )}
              </Map>
            </Wrapper>
            <Title color="black" size="xl">
              Marks
            </Title>
            <TableContainer component={Paper} style={{ maxHeight: 300 }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: 16 }}>Date</TableCell>
                    <TableCell style={{ fontSize: 16 }}>Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inspectionSelected?.points?.length ? (
                    inspectionSelected?.points?.map(
                      (point: any, index: number) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <Text size="s">
                              {new Date(point.createdAt).toLocaleDateString()}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text size="s" color={getColor(point.pointType)}>
                              {point?.pointType || "No type"}
                            </Text>
                          </TableCell>
                        </TableRow>
                      )
                    )
                  ) : (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Text size="s">No data</Text>
                      </TableCell>
                      <TableCell>
                        <Text size="s">No data</Text>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {/* <Grid xs={6}>
            <Title color="black" size="l">
              hola 3
            </Title>
          </Grid>
          <Grid xs={6}>
            <Title color="black" size="l">
              hola 4
            </Title>
          </Grid> */}
        </Grid>
      </Box>

      {/* <div>
        <Title color="black" size="l">
          hola
        </Title>
        <h1>asd</h1>
        <div>
          {statusOrder === "idle" &&
            statusInspection === "idle" &&
            order.growers.map((gwId: number) => (
              <>
                {order.growersData[gwId].fields.map((fdId: number) => (
                  <>
                    <button
                      onClick={clickField(
                        order.growersData[gwId].fieldsData[fdId]
                      )}
                    >
                      {order.growersData[gwId].fieldsData[fdId].field_id}
                    </button>
                  </>
                ))}
              </>
            ))}
        </div>
        <Wrapper apiKey="">
          <Map
            center={center}
            // onClick={onClick}
            // onIdle={onIdle}
            zoom={zoom}
            style={{ flexGrow: "1", height: "400px", width: "400px" }}
          >
            {points.length &&
              points.map((point: any, index: number) => (
                <Marker
                  key={index}
                  position={{
                    lat: point.latitude,
                    lng: point.longitude,
                  }}
                />
              ))}
          </Map>
        </Wrapper>
      </div> */}
    </Screen>
  );
};

export default InspectionReportScreen;
