export const routes = {
  login: "/login",
  orders: "/orders",
  orderDetails: "/orderDetails/:id",
  manifoldImages: "/manifoldImages/:id",
  manifoldImagesNew: "/manifoldImagesNew/:id",
  inspectionReport: "/inspectionReport/:id",
  inspectionReportDetails: "/inspectionReportDetails/:id",
  fields: "fields",
  fieldDetail: "/fieldDetail/:id",
  programConfig: "/programConfig",
  support: "/support",
  privacy: "/privacy",
  loading: "/zeedl",
  maintainer: "/maintainer",
  landing: "/zeed",
  report: "/report",
};
