import {
  Avatar,
  Box,
  Card,
  CardContent,
  LinearProgress,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";

interface PerformanceProps {
  performance: number | undefined;
}

const Performance = ({ performance }: PerformanceProps) => {
  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" gutterBottom variant="overline">
              Performance
            </Typography>
            <Typography variant="h4">{performance || "-"}%</Typography>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: "warning.main",
              height: 56,
              width: 56,
            }}
          >
            <SvgIcon>
              <OnlinePredictionIcon />
            </SvgIcon>
          </Avatar>
        </Stack>
        <Box sx={{ mt: 3 }}>
          <LinearProgress value={performance || 0} variant="determinate" />
        </Box>
      </CardContent>
    </Card>
  );
};

export default Performance;
