import { isLatLngLiteral } from "@googlemaps/typescript-guards";
import { createCustomEqual } from "fast-equals";
import { isLatLng, validLatLng } from "./util";
export const deepCompareEqualsForMaps = createCustomEqual(
  (deepEqual) => (a, b) => {
    if (
      isLatLngLiteral(a) ||
      isLatLng(a) ||
      isLatLngLiteral(b) ||
      isLatLng(b)
    ) {
      return validLatLng;
    }
    // TODO extend to other types
    // use fast-equals for other objects
    return deepEqual(a, b);
  }
);
