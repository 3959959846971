import {
  Avatar,
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";
import { useParams } from "react-router-dom";
import Title from "../../components/Typography/Title";
import Screen from "../../components/Screen/Screen";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import WorkIcon from "@mui/icons-material/Work";
import MapIcon from "@mui/icons-material/Map";
import { green, orange, blue } from "@mui/material/colors";
import { Item } from "./OrderDetailsStyled";
import classes from "./OrderDetailsScreen.module.css";
import SwiperImg from "../../components/SwiperImg/SwiperImg";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { useEffect } from "react";
import {
  orderDetailAsync,
  selectField,
  selectOrderState,
} from "./OrderDetailsSlice";
const OrderDetailsScreen = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { order, selectedField } = useAppSelector(selectOrderState);
  useEffect(() => {
    id && dispatch(orderDetailAsync({ orderId: id }));
  }, [id, dispatch]);
  const random = [
    {
      src: "https://www.zeedgo.xyz/myfile/446/manifolds/SM_1679958431187_IMG_5895.JPG",
      title: "some",
      date: "some",
      landscape: false,
    },
    {
      src: "https://www.zeedgo.xyz/myfile/446/manifolds/SM_1679958431187_IMG_5895.JPG",
      title: "some",
      date: "some",
      landscape: false,
    },
  ];
  const handleSelectField = (field: any) => {
    dispatch(selectField(field));
  };
  return (
    <Screen padding="m">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={10}>
            <Title color="black" size="xl">
              {order &&
                `Order ${order.client ? order.client.name : ""}-${
                  order.specie ? order.specie.name : ""
                }-${order.variety ? order.variety.name : ""}`}
            </Title>
          </Grid>
          <Grid item xs={4}>
            {/* <List
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: "background.paper",
                position: "relative",
                overflow: "auto",
                maxHeight: 240,
              }}
            >
              <ListItem sx={{ paddingBlock: 0 }}>
                <ListItemAvatar>
                  <Avatar sx={{ width: 32, height: 32 }}>S</Avatar>
                </ListItemAvatar>
                <ListItemText primary="Specie" secondary="Jan 9, 2014" />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem sx={{ paddingBlock: 0 }}>
                <ListItemAvatar>
                  <Avatar sx={{ width: 32, height: 32 }}>V</Avatar>
                </ListItemAvatar>
                <ListItemText primary="Variety" secondary="Jan 7, 2014" />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem sx={{ paddingBlock: 0 }}>
                <ListItemAvatar>
                  <Avatar sx={{ width: 32, height: 32 }}>R</Avatar>
                </ListItemAvatar>
                <ListItemText primary="Ratio" secondary="July 20, 2014" />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem sx={{ paddingBlock: 0 }}>
                <ListItemAvatar>
                  <Avatar sx={{ width: 32, height: 32 }}>T</Avatar>
                </ListItemAvatar>
                <ListItemText primary="Target" secondary="July 20, 2014" />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem sx={{ paddingBlock: 0 }}>
                <ListItemAvatar>
                  <Avatar sx={{ width: 32, height: 32 }}>B</Avatar>
                </ListItemAvatar>
                <ListItemText primary="Base" secondary="July 20, 2014" />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem sx={{ paddingBlock: 0 }}>
                <ListItemAvatar>
                  <Avatar sx={{ width: 32, height: 32 }}>RS</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Required surface"
                  secondary="July 20, 2014"
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem sx={{ paddingBlock: 0 }}>
                <ListItemAvatar>
                  <Avatar sx={{ width: 32, height: 32 }}>F</Avatar>
                </ListItemAvatar>
                <ListItemText primary="Fieldman" secondary="July 20, 2014" />
              </ListItem>
            </List> */}
          </Grid>
          <Grid item xs={4}>
            <Paper sx={{ padding: 2 }}>
              <Stack
                direction="row"
                spacing={2}
                paddingBottom={1}
                alignItems="center"
              >
                <Avatar sx={{ bgcolor: blue[400], width: 32, height: 32 }}>
                  <WorkIcon />
                </Avatar>
                <Title color="black" size="m">
                  Manifolds fulfilled
                </Title>
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                paddingBottom={1}
                alignItems="center"
              >
                <Avatar sx={{ bgcolor: blue[400], width: 32, height: 32 }}>
                  <MapIcon />
                </Avatar>
                <Title color="black" size="m">
                  Inspection reports fulfilled
                </Title>
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                paddingBottom={1}
                alignItems="center"
              >
                <Avatar sx={{ bgcolor: blue[400], width: 32, height: 32 }}>
                  <AccessTimeIcon />
                </Avatar>
                <Title color="black" size="m">
                  Most recent
                </Title>
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                paddingBottom={1}
                alignItems="center"
              >
                <Avatar sx={{ bgcolor: blue[400], width: 32, height: 32 }}>
                  <HourglassBottomIcon />
                </Avatar>
                <Title color="black" size="m">
                  Least recent
                </Title>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={3}>
            <Paper sx={{ padding: 2, height: "300px", overflow: "auto" }}>
              <Title>Fields</Title>
              <Stack spacing={1}>
                {order &&
                  order.fields?.length &&
                  order.fields.map((field: any) => (
                    <Paper
                      key={field.id}
                      className={classes.item}
                      onClick={() => {
                        handleSelectField(field);
                      }}
                    >
                      {field.name}-{field.fieldNumber}
                    </Paper>
                  ))}
              </Stack>
            </Paper>
          </Grid>

          <Grid item xs={3}>
            <Paper sx={{ padding: 2, height: "300px", overflow: "auto" }}>
              {selectedField?.id && (
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                    position: "relative",
                    overflow: "auto",
                    maxHeight: 240,
                  }}
                >
                  <ListItem sx={{ paddingBlock: 0 }}>
                    <ListItemAvatar>
                      <Avatar sx={{ width: 32, height: 32 }}>S</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Name"
                      secondary={selectedField.name}
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem sx={{ paddingBlock: 0 }}>
                    <ListItemAvatar>
                      <Avatar sx={{ width: 32, height: 32 }}>V</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Field number"
                      secondary={selectedField.fieldNumber}
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem sx={{ paddingBlock: 0 }}>
                    <ListItemAvatar>
                      <Avatar sx={{ width: 32, height: 32 }}>V</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Anpross number"
                      secondary={selectedField.anprossNumber}
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem sx={{ paddingBlock: 0 }}>
                    <ListItemAvatar>
                      <Avatar sx={{ width: 32, height: 32 }}>V</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Grower"
                      secondary={selectedField.grower.name}
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </List>
              )}
            </Paper>
          </Grid>
          <Grid item xs={3}>
            {/* <List
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: "background.paper",
                position: "relative",
                overflow: "auto",
                maxHeight: 300,
              }}
            >
              <ListItem sx={{ paddingBlock: 0 }}>
                <ListItemText primary="Specie" secondary="Jan 9, 2014" />
              </ListItem>
              <Divider component="li" sx={{ marginInline: 1 }} />

              <ListItem sx={{ paddingBlock: 0 }}>
                <ListItemText primary="Variety" secondary="Jan 7, 2014" />
              </ListItem>
              <Divider component="li" sx={{ marginInline: 1 }} />

              <ListItem sx={{ paddingBlock: 0 }}>
                <ListItemText primary="Ratio" secondary="July 20, 2014" />
              </ListItem>
              <Divider component="li" sx={{ marginInline: 1 }} />

              <ListItem sx={{ paddingBlock: 0 }}>
                <ListItemText primary="Target" secondary="July 20, 2014" />
              </ListItem>
              <Divider component="li" sx={{ marginInline: 1 }} />

              <ListItem sx={{ paddingBlock: 0 }}>
                <ListItemText primary="Base" secondary="July 20, 2014" />
              </ListItem>
              <Divider component="li" sx={{ marginInline: 1 }} />

              <ListItem sx={{ paddingBlock: 0 }}>
                <ListItemText
                  primary="Required surface"
                  secondary="July 20, 2014"
                />
              </ListItem>
              <Divider component="li" sx={{ marginInline: 1 }} />
              <ListItem sx={{ paddingBlock: 0 }}>
                <ListItemText primary="Fieldman" secondary="July 20, 2014" />
              </ListItem>
            </List> */}
          </Grid>
          <Grid item xs={3}>
            {/* <List
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: "background.paper",
                position: "relative",
                overflow: "auto",
                maxHeight: 300,
              }}
            >
              <ListItem sx={{ paddingBlock: 0 }}>
                <ListItemText primary="Specie" secondary="Jan 9, 2014" />
              </ListItem>
              <Divider component="li" sx={{ marginInline: 1 }} />

              <ListItem sx={{ paddingBlock: 0 }}>
                <ListItemText primary="Variety" secondary="Jan 7, 2014" />
              </ListItem>
              <Divider component="li" sx={{ marginInline: 1 }} />

              <ListItem sx={{ paddingBlock: 0 }}>
                <ListItemText primary="Ratio" secondary="July 20, 2014" />
              </ListItem>
              <Divider component="li" sx={{ marginInline: 1 }} />

              <ListItem sx={{ paddingBlock: 0 }}>
                <ListItemText primary="Target" secondary="July 20, 2014" />
              </ListItem>
              <Divider component="li" sx={{ marginInline: 1 }} />

              <ListItem sx={{ paddingBlock: 0 }}>
                <ListItemText primary="Base" secondary="July 20, 2014" />
              </ListItem>
              <Divider component="li" sx={{ marginInline: 1 }} />

              <ListItem sx={{ paddingBlock: 0 }}>
                <ListItemText
                  primary="Required surface"
                  secondary="July 20, 2014"
                />
              </ListItem>
              <Divider component="li" sx={{ marginInline: 1 }} />
              <ListItem sx={{ paddingBlock: 0 }}>
                <ListItemText primary="Fieldman" secondary="July 20, 2014" />
              </ListItem>
            </List> */}
          </Grid>
          {/* <Grid item xs={4}>
            <Paper sx={{ padding: 2 }}>
              <Title>Pictures</Title>
              <SwiperImg images={random} />
            </Paper>
          </Grid>
          <Grid item xs={8}>
            <Paper sx={{ padding: 2 }}>
              <Title>Scores</Title>
            </Paper>
          </Grid> */}
        </Grid>
      </Box>
    </Screen>
  );
};

export default OrderDetailsScreen;
