import { useMemo } from "react";

export const useOrderFilter = (data, search) => {
  return useMemo(() => {
    if (!search || search.trim() === "") {
      return data;
    }
    return data.filter((item) => {
      return (
        item.user?.name?.toLowerCase().includes(search.toLowerCase()) ||
        item.specie?.name?.toLowerCase().includes(search.toLowerCase()) ||
        item.variety?.name?.toLowerCase().includes(search.toLowerCase()) ||
        item.client?.name?.toLowerCase().includes(search.toLowerCase())
      );
    });
  }, [data, search]);
};
