import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "src/app/store";
import { deleteField, fetchFieldsByProgramAndActive } from "src/services/field";

interface FieldsState {
  fieldsList: any[];
  fieldsFiltered: any[];
  totalsGeneralStatus: any;
  totalsPerformanceStatus: any;
  status: "idle" | "loading" | "failed";
  error: any;
  filterGeneralStatus: string | null;
  filterPerformanceStatus: string | null;
  fieldActionStatus: "idle" | "loading" | "succeeded" | "failed";
}

const initialState: FieldsState = {
  fieldsList: [],
  fieldsFiltered: [],
  totalsGeneralStatus: {},
  totalsPerformanceStatus: {},
  status: "idle",
  fieldActionStatus: "idle",
  error: null,
  filterGeneralStatus: null,
  filterPerformanceStatus: null,
};

// Thunk to fetch fields
export const fetchFieldsThunk = createAsyncThunk(
  "fields/fetchFields",
  async (_, { getState }) => {
    try {
      const programId = (getState() as RootState).auth.programId; // Get the programId from the state
      const response = await fetchFieldsByProgramAndActive(programId); // Make the API call to fetch fields using the programId
      return response.data; // Assuming the response contains the list of fields
    } catch (error) {
      throw new Error("Failed to fetch fields");
    }
  }
);

// Thunk to delete a field
export const deleteFieldThunk = createAsyncThunk(
  "fields/deleteField",
  async (fieldId: number) => {
    try {
      // Make the API call to delete the field using the fieldId
      await deleteField(fieldId);
      return fieldId;
    } catch (error) {
      throw new Error("Failed to delete field");
    }
  }
);

const fieldsSlice = createSlice({
  name: "fields",
  initialState: initialState,
  reducers: {
    filterFieldsByGeneralStatus: (state, action) => {
      const generalStatus = action.payload; // Set the generalStatusFilter to the provided value
      const performanceStatus = state.filterPerformanceStatus; // Get the performanceStatusFilter from the state
      state.filterGeneralStatus = generalStatus; // Update the state with the new generalStatusFilter
      if (performanceStatus) {
        // Apply both filters if performanceStatus is present
        state.fieldsFiltered = state.fieldsList.filter(
          (field) =>
            field.generalStatus === generalStatus &&
            field.performanceStatus === performanceStatus
        );
      } else {
        // Apply only the generalStatus filter
        state.fieldsFiltered = state.fieldsList.filter(
          (field) => field.generalStatus === generalStatus
        );
      }
    },
    filterFieldsByPerformanceStatus: (state, action) => {
      const performanceStatus = action.payload; // Set the performanceStatusFilter to the provided value
      const generalStatus = state.filterGeneralStatus; // Get the generalStatusFilter from the state
      state.filterPerformanceStatus = performanceStatus; // Update the state with the new performanceStatusFilter
      if (generalStatus) {
        // Apply both filters if generalStatus is present
        state.fieldsFiltered = state.fieldsList.filter(
          (field) =>
            field.generalStatus === generalStatus &&
            field.performanceStatus === performanceStatus
        );
      } else {
        // Apply only the performanceStatus filter
        state.fieldsFiltered = state.fieldsList.filter(
          (field) => field.performanceStatus === performanceStatus
        );
      }
    },
    clearFilterGeneralStatus: (state) => {
      state.filterGeneralStatus = null; // Reset the generalStatusFilter to null
      const performanceStatus = state.filterPerformanceStatus; // Get the performanceStatusFilter from the state
      if (performanceStatus) {
        // Apply both filters if performanceStatus is present
        state.fieldsFiltered = state.fieldsList.filter(
          (field) => field.performanceStatus === performanceStatus
        );
      } else {
        // Apply only the performanceStatus filter
        state.fieldsFiltered = state.fieldsList;
      }
    },
    clearFilterPerformanceStatus: (state) => {
      state.filterPerformanceStatus = null; // Reset the performanceStatusFilter to null
      const generalStatus = state.filterGeneralStatus; // Get the generalStatusFilter from the state
      if (generalStatus) {
        // Apply both filters if generalStatus is present
        state.fieldsFiltered = state.fieldsList.filter(
          (field) => field.generalStatus === generalStatus
        );
      } else {
        // Apply only the generalStatus filter
        state.fieldsFiltered = state.fieldsList;
      }
    },
    clearFilterFields: (state) => {
      state.fieldsFiltered = state.fieldsList; // Reset the filtered fields to the original list
    },
    resetFieldActionIdle: (state) => {
      state.fieldActionStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFieldsThunk.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchFieldsThunk.fulfilled, (state, action) => {
        state.status = "idle";
        state.fieldsList = action.payload.fields; // Update the state with the fetched fields
        state.fieldsFiltered = action.payload.fields; // Update the state with the fetched fields
        state.totalsGeneralStatus = action.payload.totalsGeneralStatus; // Update the state with the fetched totals
        state.totalsPerformanceStatus = action.payload.totalsPerformanceStatus; // Update the state with the fetched totals
      })
      .addCase(fetchFieldsThunk.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteFieldThunk.pending, (state) => {
        state.fieldActionStatus = "loading";
        state.error = null;
      })
      .addCase(deleteFieldThunk.fulfilled, (state, action) => {
        state.fieldActionStatus = "succeeded";
        state.fieldsList = state.fieldsList.filter(
          (field) => field.id !== action.payload
        );
        state.fieldsFiltered = state.fieldsFiltered.filter(
          (field) => field.id !== action.payload
        );
      })
      .addCase(deleteFieldThunk.rejected, (state, action) => {
        state.fieldActionStatus = "failed";
        state.error = action.error.message;
      });
  },
});

// Export the action creator and reducer
export const selectFieldsState = (state: RootState) => state.fields;
export const {
  filterFieldsByGeneralStatus,
  filterFieldsByPerformanceStatus,
  clearFilterGeneralStatus,
  clearFilterPerformanceStatus,
  clearFilterFields,
  resetFieldActionIdle,
} = fieldsSlice.actions;

export default fieldsSlice.reducer;
