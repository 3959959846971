import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { deleteOrderAsync } from "../OrdersSlice";
const DeleteOrderModal = ({ open, onClose, onDelete, item}) => {
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(deleteOrderAsync(item.id));
    onDelete();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        Delete {item?.title || ""}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete {item?.name || "this item"}?
          This will delete all the data related to this order, including fields and reports.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleDelete} color="error">Delete</Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteOrderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};

export default DeleteOrderModal;