import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { createOrder } from "src/services/orders";

export interface CreateOrderState {
  open: boolean;
  isLoading: "idle" | "loading" | "failed";
  seasonId: number | null;
  specieId: number | null;
  varietyId: number | null;
  clientId: number | null;
  programId: number | null;
  fieldmanId: number | null;
}

const initialState: CreateOrderState = {
  open: false,
  isLoading: "idle",
  seasonId: null,
  specieId: null,
  varietyId: null,
  clientId: null,
  programId: null,
  fieldmanId: null,
};

// export const ordersAsync = createAsyncThunk("orders/fetchOrders", async () => {
//   const response = await fetchOrders();
//   return response.data;
// });

export const createOrderAsync = createAsyncThunk(
  "orders/createOrder",
  async (_, { getState }) => {
    const programId = (getState() as RootState).auth.programId;
    const createOrderForm = (getState() as RootState).createOrder;
    const response = await createOrder({
      seasonId: createOrderForm.seasonId,
      clientId: createOrderForm.clientId,
      specieId: createOrderForm.specieId,
      varietyId: createOrderForm.varietyId,
      userId: createOrderForm.fieldmanId,
      programId,
    });
    return response;
  }
);

export const createOrderSlice = createSlice({
  name: "createOrderSlice",
  initialState,
  reducers: {
    toggleModal: (state) => {
      state.open = !state.open;
    },
    setFormValueSimple: <K extends keyof CreateOrderState>(
      state: any,
      action: PayloadAction<{ name: K; value: CreateOrderState[K] }>
    ) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    resetFormValue: <K extends keyof CreateOrderState>(
      state: any,
      action: PayloadAction<{ name: K }>
    ) => {
      const { name } = action.payload;
      state[name] = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrderAsync.pending, (state) => {
        state.isLoading = "loading";
      })
      .addCase(createOrderAsync.fulfilled, (state, action) => {
        state.isLoading = "idle";
        state.open = false;
      })
      .addCase(createOrderAsync.rejected, (state) => {
        state.isLoading = "failed";
      });
  },
});

export const { toggleModal, setFormValueSimple, resetFormValue } =
  createOrderSlice.actions;

//SELECTORS
export const selectCreateOrderState = (state: RootState) => state.createOrder;

export default createOrderSlice.reducer;
