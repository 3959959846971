import React, { ReactNode } from "react";
import classes from "./Screen.module.css";
interface Props {
  padding?: "xs" | "s" | "m" | "l" | "xl";
  children?: ReactNode[] | ReactNode;
}
const Screen = ({ children, padding }: Props) => {
  const paddingClass = padding ? classes[`${padding}Pad`] : classes[`noPad`];
  return (
    <div className={[classes.Screen, paddingClass].join(" ")}>{children}</div>
  );
};

export default Screen;
