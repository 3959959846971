import axios from "./axios";
import api from "./api";

export const fetchManifoldById = async (id: String) => {
  return await axios.get(`/manifold_id/${id}`);
};

export const fetchManifoldS3ById = async (id: String) => {
  return await api.get(`/manifold/${id}`);
};
