// import axios from "./axios";
import axios from "axios";
import { API_URL } from "src/config/environment";
import { LoginResponse } from "src/models/response/UserLogin";
const url = API_URL; //
export const login = async (credentials: {
  username: string;
  password: string;
}): Promise<LoginResponse> => {
  const response = await axios.post(`${url}/auth/login`, {
    email: credentials.username,
    password: credentials.password,
  });
  return response.data;
};

export const refreshToken = async (token: string): Promise<LoginResponse> => {
  const response = await axios.get(`${url}/auth/login-refresh`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
