import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "src/app/store";
import {
  createEmailConfig,
  deleteEmailConfig,
  getEmailConfigsByProgram,
  getProgramDefault,
  updateSeasonDefault,
} from "src/services/programConfig";
import { createSeason } from "src/services/season";

interface ProgramConfigState {
  content: [{}] | null;
  isLoading: boolean;
  error: string | null | undefined;
  programDefault: any;
}

const initialState: ProgramConfigState = {
  content: null,
  isLoading: false,
  error: null,
  programDefault: null,
};

export const getEmailConfigsByProgramThunk = createAsyncThunk(
  "programConfig/getEmailConfigsByProgram",
  async (_, { getState }) => {
    const programId = (getState() as RootState).auth.programId;
    const response = await getEmailConfigsByProgram(programId);
    return { content: response.data };
  }
);

export const createEmailConfigThunk = createAsyncThunk(
  "programConfig/createEmailConfig",
  async (payload: { email: string; reportType: string }, { getState }) => {
    const programId = (getState() as RootState).auth.programId;
    const response = await createEmailConfig(
      payload.email,
      programId,
      payload.reportType
    );
    return {
      id: response.data.id,
      email: payload.email,
      reportType: payload.reportType,
    };
  }
);

export const deleteEmailConfigThunk = createAsyncThunk(
  "programConfig/deleteEmailConfig",
  async (id: number, { dispatch }) => {
    await deleteEmailConfig(id);
    dispatch(getEmailConfigsByProgramThunk());
    return;
  }
);

export const programDefaultThunk = createAsyncThunk(
  "programConfig/programDefault",
  async (_, { getState }) => {
    const programId = (getState() as RootState).auth.programId;
    const response = await getProgramDefault(programId);
    return response.data;
  }
);

export const createSeasonThunk = createAsyncThunk(
  "programConfig/createSeason",
  async (name: string, { getState }) => {
    const programId = (getState() as RootState).auth.programId;
    const response = await createSeason({ programId, name });
    return response.data;
  }
);

export const updateSeasonDefaultThunk = createAsyncThunk(
  "programConfig/updateSeasonDefault",
  async (seasonId: number, { getState }) => {
    const programId = (getState() as RootState).auth.programId;
    const response = await updateSeasonDefault(programId, seasonId);
    return response.data;
  }
);

const programConfigSlice = createSlice({
  name: "programConfig",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEmailConfigsByProgramThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEmailConfigsByProgramThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.content = action.payload.content;
      })
      .addCase(getEmailConfigsByProgramThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(createEmailConfigThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createEmailConfigThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.content?.push({
          id: action.payload.id,
          email: action.payload.email,
          reportType: action.payload.reportType,
        });
      })
      .addCase(createEmailConfigThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(deleteEmailConfigThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteEmailConfigThunk.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteEmailConfigThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(programDefaultThunk.fulfilled, (state, action) => {
        state.programDefault = action.payload;
      })
      .addCase(createSeasonThunk.fulfilled, (state, action) => {
        // @ts-ignore
      });
  },
});

export default programConfigSlice.reducer;

export const selectProgramConfig = (state: RootState) =>
  state.programConfig.content;

export const selectProgramConfigLoading = (state: RootState) =>
  state.programConfig.isLoading;

export const selectProgramConfigError = (state: RootState) =>
  state.programConfig.error;

export const selectProgramConfigState = (state: RootState) => {
  return state.programConfig;
};
