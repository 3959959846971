import { useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Grid, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { fieldmanAsync, selectOrdersState } from "../OrdersSlice";
import { selectAuthState } from "../../LoginScreen/LoginSlice";
import {
  addFieldmanToOrderAsync,
  resetFormValueFieldman,
  selectAddFieldmanToOrderState,
  setFormValueSimpleFieldman,
  toggleModalFieldman,
} from "../AddFieldmanToOrderSlice";

const AddFieldmanToOrderDialog = () => {
  const dispatch = useAppDispatch();
  const { open, isLoading } = useAppSelector(selectAddFieldmanToOrderState);
  const { fieldmans } = useAppSelector(selectOrdersState);
  const { programId } = useAppSelector(selectAuthState);

  useEffect(() => {
    if (!fieldmans?.length) {
      dispatch(fieldmanAsync());
    }
  }, [dispatch, programId, fieldmans]);

  const handleClose = () => {
    dispatch(toggleModalFieldman());
  };

  const handleChange = (name: any, value: any) => {
    if (value) {
      dispatch(setFormValueSimpleFieldman({ name, value: value.id }));
    } else {
      dispatch(resetFormValueFieldman({ name }));
    }
  };

  const handleSave = () => {
    dispatch(addFieldmanToOrderAsync());
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add fieldman</DialogTitle>
      <DialogContent>
        <DialogContentText>Add fieldman to order and fields</DialogContentText>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={fieldmans}
              sx={{ marginBlock: 1 }}
              onChange={(_, v: any) => {
                handleChange("fieldmanId", v);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Fieldman" />
              )}
            />
          </Grid>
          {isLoading === "failed" && (
            <Typography variant="body2" color="error">
              Error adding fieldman
            </Typography>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSave}>Create</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddFieldmanToOrderDialog;
