import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { attachFieldmanToORder, createOrder } from "src/services/orders";

export interface AddFieldmanState {
  open: boolean;
  isLoading: "idle" | "loading" | "failed";
  programId: number | null;
  fieldmanId: number | null;
  orderId: number | null;
}

const initialState: AddFieldmanState = {
  open: false,
  isLoading: "idle",
  programId: null,
  fieldmanId: null,
  orderId: null,
};

export const addFieldmanToOrderAsync = createAsyncThunk(
  "fieldman/updateFieldmanOrder",
  async (_, { getState }) => {
    const form = (getState() as RootState).addFieldman;
    const response = await attachFieldmanToORder({
      orderId: form.orderId,
      userId: form.fieldmanId,
    });
    return response;
  }
);

export const AddFieldmanToOrderSlice = createSlice({
  name: "addFieldmanToOrderSlice",
  initialState,
  reducers: {
    toggleModalFieldman: (state) => {
      state.open = !state.open;
    },
    setFormValueSimpleFieldman: <K extends keyof AddFieldmanState>(
      state: any,
      action: PayloadAction<{ name: K; value: AddFieldmanState[K] }>
    ) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    resetFormValueFieldman: <K extends keyof AddFieldmanState>(
      state: any,
      action: PayloadAction<{ name: K }>
    ) => {
      const { name } = action.payload;
      state[name] = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addFieldmanToOrderAsync.pending, (state) => {
        state.isLoading = "loading";
      })
      .addCase(addFieldmanToOrderAsync.fulfilled, (state, action) => {
        state.isLoading = "idle";
        state.open = false;
      })
      .addCase(addFieldmanToOrderAsync.rejected, (state) => {
        state.isLoading = "failed";
      });
  },
});

export const {
  toggleModalFieldman,
  setFormValueSimpleFieldman,
  resetFormValueFieldman,
} = AddFieldmanToOrderSlice.actions;

//SELECTORS
export const selectAddFieldmanToOrderState = (state: RootState) =>
  state.addFieldman;

export default AddFieldmanToOrderSlice.reducer;
