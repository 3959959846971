import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  Alert,
  Card,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";
import UpsertGrowerModal from "../Modals/UpsertGrowerModal";
import SearchBarMaintainer from "../MaintainerComponents/SearchBarMaintainer";
import { useFilter } from "../MaintainerUtils/useFilter";
import { usePagination } from "../MaintainerUtils/usePagination";
import Loader from "../../../components/Loader/Loader";
import UpsertFieldmanModal from "../Modals/UpsertFieldmanModal";
import UpsertClientModal from "../Modals/UpserClientModal";
import UpsertSpecieModal from "../Modals/UpserSpecieModal";
import UpsertVarietyModal from "../Modals/UpsertVarietyModal";
import DeleteModal from "../Modals/DeleteModal";

const GenericTable = ({
  name,
  content,
  isLoading,
  entityStatus,
  entityThunk,
  resetIdle,
  labelsHead,
  keys,
}) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchText, setSearchText] = useState("");
  const contentFiltered = useFilter(content, searchText);
  const contentPage = usePagination(contentFiltered, page, rowsPerPage);

  useEffect(() => {
    if (entityStatus === "succeeded") {
      dispatch(entityThunk());
      setOpenAlert(true);
    }
    if (entityStatus === "failed") {
      setOpenAlert(true);
    }
  }, [entityStatus, entityThunk, dispatch]);

  const handlePageChange = useCallback((event, value) => {
    setPage(value);
  }, []);

  const handleRowsPerPageChange = useCallback((event) => {
    setRowsPerPage(event.target.value);
  }, []);

  const handleCreateModal = () => {
    setSelectedItem(null);
    setOpenModal(!openModal);
  };

  const handleUpdateModal = (grower) => {
    setSelectedItem(grower);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedItem(null);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
    dispatch(resetIdle());
  };

  const handleDeleteModal = (item) => {
    setSelectedItem(item);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedItem(null);
  };

  const getModalForName = () => {
    switch (name) {
      case "grower":
        return (
          <UpsertGrowerModal
            open={openModal}
            onClose={handleCloseModal}
            grower={selectedItem}
          />
        );
      case "fieldman":
        return (
          <UpsertFieldmanModal
            open={openModal}
            onClose={handleCloseModal}
            fieldman={selectedItem}
          />
        );
      case "client":
        return (
          <UpsertClientModal
            open={openModal}
            onClose={handleCloseModal}
            client={selectedItem}
          />
        );
      case "specie":
        return (
          <UpsertSpecieModal
            open={openModal}
            onClose={handleCloseModal}
            specie={selectedItem}
          />
        );
      case "variety":
        return (
          <UpsertVarietyModal
            open={openModal}
            onClose={handleCloseModal}
            variety={selectedItem}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Card>
      {entityStatus === "loading" || isLoading ? (
        <Loader />
      ) : (
        <>
          <SearchBarMaintainer
            name={name}
            onSearch={handleSearch}
            onCreate={handleCreateModal}
          />
          <Table>
            <TableHead>
              <TableRow>
                {labelsHead.map((label, index) => (
                  <TableCell key={index}>
                    <Typography variant="h6">{label}</Typography>
                  </TableCell>
                ))}
                <TableCell>
                  <Typography variant="h6">Actions</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contentPage &&
                contentPage.map((item) => (
                  <TableRow hover key={item.id}>
                    {keys.map((key, index) => (
                      <TableCell key={index}>
                        <Typography variant="subtitle2">
                          {item[key] || ""}
                        </Typography>
                      </TableCell>
                    ))}
                    <TableCell>
                      {name!=="fieldman"&&<Typography variant="subtitle2">
                        <IconButton
                          onClick={() => {
                            handleUpdateModal(item);
                          }}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            handleDeleteModal(item);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Typography>}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={contentFiltered?.length || 0}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
          {openModal && getModalForName()}
          {openDeleteModal && (
            <DeleteModal
              onClose={handleCloseDeleteModal}
              onDelete={handleCloseDeleteModal}
              item={selectedItem}
              name={name}
              open={openDeleteModal}
            />
          )}
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={openAlert}
            onClose={handleCloseAlert}
            autoHideDuration={3000}
          >
            {(entityStatus ==="succeeded" || entityStatus === "failed")&& <Alert
              onClose={handleCloseAlert}
              severity={entityStatus === "succeeded" ? "success" : "error"}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {entityStatus === "succeeded"
                ? "Operation succeeded"
                : "Operation failed"}
            </Alert>}
          </Snackbar>
        </>
      )}
    </Card>
  );
};

GenericTable.propTypes = {
  name: PropTypes.string.isRequired,
  content: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  entityStatus: PropTypes.string.isRequired,
  entityThunk: PropTypes.func.isRequired,
  resetIdle: PropTypes.func.isRequired,
  labelsHead: PropTypes.array.isRequired,
  keys: PropTypes.array.isRequired,
};

export default GenericTable;
