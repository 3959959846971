import pptxgen from "pptxgenjs";

const FONT_COLOR_GREEN = "0b7751";
const FONT_COLOR_GRAY = "A1A1A1";

export const generatePPT = async (data, clients) => {
  const pptx = new pptxgen();
  let slide = pptx.addSlide();

  let yTitle = 5;

  let clientsNames = [];

  //ADD CLIENT NAMES
  clients.forEach((element) => {
    clientsNames.push(element.label.trim());
    slide.addText(element.label, {
      x: "5%",
      y: `${yTitle}%`,
      w: "90%",
      h: 0.33,
      fontSize: 20,
      align: "left",
      color: FONT_COLOR_GREEN,
    });
    yTitle += 5;
  });

  //ADD DATE
  slide.addText(new Date().toLocaleDateString(), {
    x: "5%",
    y: "5%",
    w: "90%",
    h: 0.33,
    fontSize: 20,
    align: "right",
    color: FONT_COLOR_GRAY,
  });

  //OTHER SLIDE

  data.forEach((element, index) => {
    if (element.manifold) {
      let manifold = element.manifold;
      if (manifold.generalLgUrl) {
        slide = pptx.addSlide();
        let date = null;
        if (manifold.createdAt) {
          date = new Date(manifold.createdAt).toLocaleDateString();
        }
        addTitleForGeneral(
          slide,
          `${element.growerName} - ${element.varietyName}${
            date ? " - " + date : ""
          }`
        );
        addFullImage(slide, manifold.generalLgUrl);
      }
      if (manifold.femaleLgUrl && manifold.male1LgUrl) {
        slide = pptx.addSlide();
        addImageLeft(slide, manifold.femaleLgUrl);
        addSubtitleLeft(slide, "Female");
        addImageRight(slide, manifold.male1LgUrl);
        addSubtitleRight(slide, "Male 1");
      }
      if (manifold.femaleLgUrl && !manifold.male1LgUrl) {
        slide = pptx.addSlide();
        addImageCenter(slide, manifold.femaleLgUrl);
        addSubtitleCenter(slide, "Female");
      }
      if (manifold.male2LgUrl && manifold.male3LgUrl) {
        slide = pptx.addSlide();
        addImageLeft(slide, manifold.male2LgUrl);
        addSubtitleLeft(slide, "Male 2");
        addImageRight(slide, manifold.male3LgUrl);
        addSubtitleRight(slide, "Male 3");
      }
      if (manifold.male2LgUrl && !manifold.male3LgUrl) {
        slide = pptx.addSlide();
        addImageCenter(slide, manifold.male2LgUrl);
        addSubtitleCenter(slide, "Male 2");
      }
    }
  });

  const clientNamesStr = clientsNames.join("_");
  return pptx.writeFile({
    fileName: `report_${clientNamesStr}_${new Date().toLocaleDateString()}.pptx`,
  });
};

const addImageLeft = (slide, url) => {
  slide.addImage({ path: url, x: "5%", y: "10%", w: "40%", h: "80%" });
};

const addImageRight = (slide, url) => {
  slide.addImage({ path: url, x: "50%", y: "10%", w: "40%", h: "80%" });
};

const addImageCenter = (slide, url) => {
  slide.addImage({ path: url, x: "30%", y: "10%", w: "40%", h: "80%" });
};

const addFullImage = (slide, url) => {
  slide.addImage({ path: url, x: "5%", y: "10%", w: "90%", h: "80%" });
};

const addTitleForGeneral = (slide, title) => {
  slide.addText(title, {
    x: "5%",
    y: "5%",
    w: "90%",
    h: 0.33,
    fontSize: 18,
    align: "center",
    color: "A1A1A1",
  });
};

const addSubtitleLeft = (slide, text) => {
  slide.addText(text, {
    x: "5%",
    y: "90%",
    w: "40%",
    h: 0.33,
    fontSize: 18,
    align: "center",
    fill: "E1E1E1",
    color: "A1A1A1",
  });
};
const addSubtitleRight = (slide, text) => {
  slide.addText(text, {
    x: "50%",
    y: "90%",
    w: "40%",
    h: 0.33,
    fontSize: 18,
    align: "center",
    fill: "E1E1E1",
    color: "A1A1A1",
  });
};

const addSubtitleCenter = (slide, text) => {
  slide.addText(text, {
    x: "30%",
    y: "90%",
    w: "40%",
    h: 0.33,
    fontSize: 18,
    align: "center",
    fill: "E1E1E1",
    color: "A1A1A1",
  });
};
