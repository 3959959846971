import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useSelector } from "react-redux";
import { selectReportState } from "../report-slice";
import { LoadingButton } from "@mui/lab";

const ReportFilterComponent = (props) => {
  const { clientFilter, specieFilter, varietyFilter, numberFilter,
    handleClientFilter, handleSpecieFilter, handleVarietyFilter, handleNumberFilter, handleGenerate, loadingGenerate } = props;
  const { speciesList, varietyList, clientsList, fieldNumberList } = useSelector(selectReportState);

  return (
    <Box p={2}>
      <Box flexDirection="row" justifyContent="space-between" display="flex">
      <Typography variant="body1" gutterBottom>
        Filters
      </Typography>
      <Box>
      <LoadingButton
              variant="contained"
              sx={{marginBottom:1}}
              disabled={clientFilter.length === 0}
              color="success"
              onClick={handleGenerate}
              endIcon={<CloudDownloadIcon/>}
              loadingPosition="end"
              loading={loadingGenerate}
            >
              Generate
            </LoadingButton>
      </Box>
      </Box>
     
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <Autocomplete
            value={clientFilter}
            onChange={handleClientFilter}
            multiple
            disablePortal
            size="small"
            id="combo-box-demo1"
            options={clientsList}
            renderInput={(params) => <TextField {...params} label="Client" />}
          />

        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            value={specieFilter}
            onChange={handleSpecieFilter}
            multiple
            disablePortal
            size="small"
            id="combo-box-demo2"
            options={speciesList}
            renderInput={(params) => <TextField {...params} label="Specie" />}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            value={varietyFilter}
            onChange={handleVarietyFilter}
            multiple
            disablePortal
            id="combo-box-demo3"
            size="small"
            options={varietyList}
            renderInput={(params) => <TextField {...params} label="Variety" />}
          />

        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            value={numberFilter}
            onChange={handleNumberFilter}
            multiple
            disablePortal
            id="combo-box-demo4"
            size="small"
            options={fieldNumberList}
            renderInput={(params) => <TextField {...params} label="Field number" />}
          />
        </Grid>

      </Grid>

    </Box>
  )
}

export default ReportFilterComponent;

ReportFilterComponent.propTypes = {
  clientFilter: PropTypes.array,
  specieFilter: PropTypes.array,
  varietyFilter: PropTypes.array,
  numberFilter: PropTypes.array,
  handleClientFilter: PropTypes.func,
  handleSpecieFilter: PropTypes.func,
  handleVarietyFilter: PropTypes.func,
  handleNumberFilter: PropTypes.func,
  handleGenerate: PropTypes.func,
  loadingGenerate: PropTypes.bool

}


