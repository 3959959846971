import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import manifoldImagesReducer from "../screens/ManifoldImagesScreen/ManifoldImagesSlice";
import inspectionReducer from "../screens/InspectionReport/InspectionReportSlice";
import ordersReducer from "../screens/OrdersScreen/OrdersSlice";
import orderDetailReducer from "../screens/OrderDetails/OrderDetailsSlice";
import createOrderReducer from "../screens/OrdersScreen/CreateOrderSlice";
import addFieldToOrderReducer from "../screens/OrdersScreen/add-field-to-order-slice";
import authReducer from "../screens/LoginScreen/LoginSlice";
import maintainerReducer from "../screens/MaintainerScreen/maintainer-slice";
import programConfigReducer from "../screens/ProgramConfig/ProgramConfigSlice";
import AddFieldmanToOrderDialog from "src/screens/OrdersScreen/AddFieldmanToOrderSlice";
import fieldsReducer from "src/screens/FieldsScreen/field-slice";
import fieldDetail from "src/screens/FieldDetailScreen/field-detail-slice";
import reportReducer from "src/screens/ReportScreen/report-slice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    manifoldImages: manifoldImagesReducer,
    inspection: inspectionReducer,
    orders: ordersReducer,
    orderDetail: orderDetailReducer,
    createOrder: createOrderReducer,
    addFieldToOrder: addFieldToOrderReducer,
    auth: authReducer,
    maintainer: maintainerReducer,
    programConfig: programConfigReducer,
    addFieldman: AddFieldmanToOrderDialog,
    fields: fieldsReducer,
    fieldDetail: fieldDetail,
    report: reportReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
