import { MaintainerResponse } from "src/models/response/maintainer";
import api from "./api";

export const fetchFieldmans = async (
  programId: number
): Promise<MaintainerResponse[]> => {
  const response = await api.get(`user?programId=${programId}`);
  return response.data;
};

export const fetchFieldmansNoType = async (programId: number): Promise<any> => {
  const response = await api.get(`user?programId=${programId}`);
  return response.data;
};

export const createFieldman = async (
  name: string,
  programId: number,
  email: string,
  phone: string,
  roles: string[]
): Promise<number> => {
  const response = await api.post("auth/register", {
    email,
    name,
    programId,
    roles,
    phone,
  });
  return response.data;
};
