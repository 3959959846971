import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  attachFieldToOrder,
  fetchFieldsCandidatesForOrder,
} from "src/services/field";
import exp from "constants";

export interface AddFieldToOrderState {
  open: boolean;
  orderSelected: number | null;
  fieldsCandidates: any[];
  fieldsCandidatesStatus: "idle" | "loading" | "succeeded" | "failed";
}

const initialState: AddFieldToOrderState = {
  open: false,
  orderSelected: null,
  fieldsCandidates: [],
  fieldsCandidatesStatus: "idle",
};

// export const ordersAsync = createAsyncThunk("orders/fetchOrders", async () => {
//   const response = await fetchOrders();
//   return response.data;
// });

export const fetchFieldsForOrderThunk = createAsyncThunk(
  "addFieldToOrder/fetchFieldsForOrder",
  async (_, { getState }) => {
    const orderSelected = (getState() as RootState).addFieldToOrder
      .orderSelected;
    if (!orderSelected) throw new Error("No order selected");
    const response = await fetchFieldsCandidatesForOrder(orderSelected);
    return response.data;
  }
);

export const addFieldToOrderThunk = createAsyncThunk(
  "addFieldToOrder/addFieldToOrder",
  async (fieldId: number, { getState }) => {
    const orderSelected = (getState() as RootState).addFieldToOrder
      .orderSelected;
    if (!orderSelected) throw new Error("No order selected");
    const response = await attachFieldToOrder(fieldId, orderSelected);
    return response.data;
  }
);

export const addFieldToOrderSlice = createSlice({
  name: "addFieldToOrderSlice",
  initialState,
  reducers: {
    toggleAddFieldModal: (state) => {
      state.open = !state.open;
    },
    selectOrder: (state, action) => {
      state.orderSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFieldsForOrderThunk.pending, (state) => {
      state.fieldsCandidatesStatus = "loading";
    });
    builder.addCase(fetchFieldsForOrderThunk.fulfilled, (state, action) => {
      state.fieldsCandidates = action.payload;
      state.fieldsCandidatesStatus = "succeeded";
    });
    builder.addCase(fetchFieldsForOrderThunk.rejected, (state) => {
      state.fieldsCandidatesStatus = "failed";
    });
  },
});

export const { toggleAddFieldModal, selectOrder } =
  addFieldToOrderSlice.actions;

//SELECTORS
export const selectAddFieldToOrderState = (state: RootState) =>
  state.addFieldToOrder;
export const selectFieldsCandidates = (state: RootState) =>
  state.addFieldToOrder.fieldsCandidates;

export default addFieldToOrderSlice.reducer;
