import api from "./api";

const EMAIL_CONFIG_URL = "/email-config";
const PROGRAM_DEFAULT_URL = "/program-default";

export const createEmailConfig = async (
  email: string,
  programId: number,
  reportType: string
) => {
  return await api.post(EMAIL_CONFIG_URL, {
    email,
    programId,
    reportType,
  });
};

export const deleteEmailConfig = async (id: number) => {
  return await api.delete(`${EMAIL_CONFIG_URL}/${id}`);
};

export const getEmailConfigsByProgram = async (programId: number) => {
  return await api.get(`${EMAIL_CONFIG_URL}?programId=${programId}`);
};

export const getProgramDefault = async (programId: number) => {
  return await api.get(
    `${PROGRAM_DEFAULT_URL}/find-by-program-id/${programId}`
  );
};

export const updateSeasonDefault = async (
  programId: number,
  seasonId: number
) => {
  return await api.put(
    `${PROGRAM_DEFAULT_URL}/update-season-default-by-program-id/${programId}/${seasonId}`
  );
};
