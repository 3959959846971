import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { fetchManifoldById } from "../../services/manifolds";

export interface ManifoldState {
  manifold: { [key: string]: any };
  status: "idle" | "loading" | "failed";
}

const initialState: ManifoldState = {
  manifold: {},
  status: "idle",
};

export const manifoldAsync = createAsyncThunk(
  "manifold/fetchManifold",
  async (id: String) => {
    const response = await fetchManifoldById(id);
    return response.data;
  }
);

export const manifoldSlice = createSlice({
  name: "manifoldSlice",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(manifoldAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(manifoldAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.manifold = action.payload;
      })
      .addCase(manifoldAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const selectManifoldImages = (state: RootState) =>
  state.manifoldImages.manifold;

export default manifoldSlice.reducer;
