import {
  Avatar,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import DangerousIcon from "@mui/icons-material/Dangerous";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import {
  ALERT_STATUS_AVATAR_COLORS,
  ALERT_STATUS_LABELS,
} from "src/constants/dictionary";

interface FieldStatusProps {
  status: string;
}

export const FieldStatus = ({ status }: FieldStatusProps) => {
  const getAlertStatusLabel = (status: any) => {
    return ALERT_STATUS_LABELS[status] || ALERT_STATUS_LABELS.default;
  };
  const getAlerStatusColor = (status: any) => {
    return (
      ALERT_STATUS_AVATAR_COLORS[status] || ALERT_STATUS_AVATAR_COLORS.default
    );
  };
  const getAlertStatusIcon = (status: any) => {
    switch (status) {
      case "RED":
        return <DangerousIcon />;
      case "YELLOW":
        return <PriorityHighIcon />;
      case "GREEN":
        return <CheckCircleIcon />;
      default:
        return <DoDisturbOnIcon />;
    }
  };
  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="overline">
              Status
            </Typography>
            <Typography variant="h4">{getAlertStatusLabel(status)}</Typography>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: getAlerStatusColor(status),
              height: 56,
              width: 56,
            }}
          >
            <SvgIcon>{getAlertStatusIcon(status)}</SvgIcon>
          </Avatar>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default FieldStatus;
