import {
  Avatar,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

interface FieldSummaryProps {
  fieldName: string;
  fieldNumber: string | number;
  fieldSpecie: string;
  fieldVariety: string;
}

const FieldSummary = ({
  fieldName,
  fieldNumber,
  fieldSpecie,
  fieldVariety,
}: FieldSummaryProps) => {
  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={0}>
            <Typography color="text.secondary" variant="overline">
              Summary
            </Typography>
            <Typography color="text.secondary" variant="caption">
              {fieldName}
            </Typography>
            <Typography color="text.secondary" variant="caption">
              {fieldNumber}
            </Typography>
            <Typography color="text.secondary" variant="caption">
              {fieldSpecie}
            </Typography>
            <Typography color="text.secondary" variant="caption">
              {fieldVariety}
            </Typography>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: "info.main",
              height: 56,
              width: 56,
            }}
          >
            <SvgIcon>
              <FormatListBulletedIcon />
            </SvgIcon>
          </Avatar>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default FieldSummary;
