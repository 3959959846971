import axios from "./axios";
import api from "./api";

export const fetchInspectionByOrder = async (id: String) => {
  return await axios.get(`/inspection_by_order/${id}`);
};
export const fetchInspectionPointsByField = async (id: number) => {
  return await axios.get(`/inspection_points_by_field/${id}`);
};

const INSPECTION_URL = "/quality";

export const fetchInspectionsByField = async (id: number) => {
  return await api.get(`${INSPECTION_URL}?fieldId=${id}`);
};
