import { MaintainerResponse } from "src/models/response/maintainer";
import api from "./api";
export const fetchSeasons = async (
  programId: number
): Promise<MaintainerResponse[]> => {
  const response = await api.get(`season?programId=${programId}`);
  return response.data;
};

export const createSeason = async (request: {
  programId: number;
  name: string;
}) => {
  const response = api.post("/season", request);
  return response;
};
