import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "src/hooks/authHook";
import { routes } from "../routes";

export const ProtectedLayout = () => {
  const { user } = useAuth();
  const location = useLocation();
  if (!user) {
    return <Navigate to={routes.login} state={{ location }} />;
  }

  return (
    <div>
      <Outlet />
    </div>
  );
};
