import { useEffect, useCallback } from "react";
import {
  Typography,
  Box,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getClientsThunk,
  getFieldmanThunk,
  getGrowersThunk,
  getSpeciesThunk,
  getVarietiesThunk,
  selectMaintainerState,
  resetIdleClient,
  resetIdleFieldman,
  resetIdleGrower,
  resetIdleSpecie,
  resetIdleVariety,
} from "./maintainer-slice";
import { useQuery } from "src/utils/useQuery";
import GenericTable from "./Tables/GenericTable";
import {
  HEADER_LABELS_CLIENT,
  HEADER_LABELS_FIELDMAN,
  HEADER_LABELS_GROWER,
  HEADER_LABELS_SPECIE,
  HEADER_LABELS_VARIETY,
  KEYS_CLIENT,
  KEYS_FIELDMAN,
  KEYS_GROWERS,
  KEYS_SPECIE,
  KEYS_VARIETY,
} from "./MaintainerConstants/maintainer-constants";
import { useNavigate } from 'react-router-dom';
const MaintainerScreen = () => {
  const navigate = useNavigate();
  let query = useQuery();
  const typeSelected = query.get("type") || "client";
  const { content, isLoading, growerStatus, fieldmanStatus, clientStatus, specieStatus, varietyStatus } = useSelector(
    selectMaintainerState
  );
  const dispatch = useDispatch();


  const handleToggle = (event, newValue) => {
    switch (newValue) {
      case "client":
        navigate("/maintainer?type=client");
        break;
      case "specie":
        navigate("/maintainer?type=specie");
        break;
      case "variety":
        navigate("/maintainer?type=variety");
        break;
      case "grower":
        navigate("/maintainer?type=grower");
        break;
      case "fieldman":
        navigate("/maintainer?type=fieldman");
        break;
      default:
        break;
    }
  };

  const handleFetch = useCallback(() => {
    switch (typeSelected) {
      case "client":
        dispatch(getClientsThunk());
        break;
      case "specie":
        dispatch(getSpeciesThunk());
        break;
      case "variety":
        dispatch(getVarietiesThunk());
        break;
      case "grower":
        dispatch(getGrowersThunk());
        break;
      case "fieldman":
        dispatch(getFieldmanThunk());
        break;
      default:
        dispatch(getClientsThunk());
        break;
     }
  }, [dispatch, typeSelected]);

    useEffect(() => {
      handleFetch();
    }, [handleFetch]);
  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom>
        Maintainer
      </Typography>
      <Box>
        <ToggleButtonGroup
          value={typeSelected || "client"}
          exclusive
          onChange={handleToggle}
          aria-label="Element options"
        >
          <ToggleButton value="client" aria-label="Option 1">
            Client
          </ToggleButton>
          <ToggleButton value="specie" aria-label="Option 2">
            Specie
          </ToggleButton>
          <ToggleButton value="variety" aria-label="Option 3">
            Variety
          </ToggleButton>
          <ToggleButton value="grower" aria-label="Option 4">
            Grower
          </ToggleButton>
          <ToggleButton value="fieldman" aria-label="Option 5">
            Fieldman
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {typeSelected === "grower" && (
        <GenericTable
          name="grower"
          content={content}
          isLoading={isLoading}
          entityStatus={growerStatus}
          resetIdle={resetIdleGrower}
          entityThunk={getGrowersThunk}
          labelsHead={HEADER_LABELS_GROWER}
          keys={KEYS_GROWERS}
        />
      )}
      {typeSelected === "fieldman" && (
        <GenericTable
          name="fieldman"
          content={content}
          isLoading={isLoading}
          entityStatus={fieldmanStatus}
          resetIdle={resetIdleFieldman}
          entityThunk={getFieldmanThunk}
          labelsHead={HEADER_LABELS_FIELDMAN}
          keys={KEYS_FIELDMAN}
        />
      )}
      {typeSelected === "specie" && (
        <GenericTable
          name="specie"
          content={content}
          isLoading={isLoading}
          entityStatus={specieStatus}
          resetIdle={resetIdleSpecie}
          entityThunk={getSpeciesThunk}
          labelsHead={HEADER_LABELS_SPECIE}
          keys={KEYS_SPECIE}
        />
      )}
      {typeSelected === "variety" && (
        <GenericTable
          name="variety"
          content={content}
          isLoading={isLoading}
          entityStatus={varietyStatus}
          resetIdle={resetIdleVariety}
          entityThunk={getVarietiesThunk}
          labelsHead={HEADER_LABELS_VARIETY}
          keys={KEYS_VARIETY}
        />
      )}
      {typeSelected === "client" && (
        <GenericTable
          name="client"
          content={content}
          isLoading={isLoading}
          entityStatus={clientStatus}
          resetIdle={resetIdleClient}
          entityThunk={getClientsThunk}
          labelsHead={HEADER_LABELS_CLIENT}
          keys={KEYS_CLIENT}
        />
      )}
    </Box>
  );
};

export default MaintainerScreen;
