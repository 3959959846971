import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Autocomplete,
  Avatar,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import {
  fieldmanAsync,
  seasonsAsync,
  selectOrdersState,
  varietyAsync,
} from "./OrdersSlice";
import { selectAuthState } from "../LoginScreen/LoginSlice";
import {
  addFieldToOrderThunk,
  selectAddFieldToOrderState,
  selectFieldsCandidates,
  toggleAddFieldModal,
} from "./add-field-to-order-slice";
import { set } from "react-hook-form";

const AddFieldToOrderDialog = () => {
  const dispatch = useAppDispatch();
  const [selectedField, setSelectedField] = useState<any | null>(null);
  const { open } = useAppSelector(selectAddFieldToOrderState);
  const { clients, species, variety, fieldmans, seasons } =
    useAppSelector(selectOrdersState);
  const { programId } = useAppSelector(selectAuthState);
  const fieldsCandidates = useAppSelector(selectFieldsCandidates);

  useEffect(() => {
    if (!variety?.length) {
      dispatch(varietyAsync());
    }
    if (!fieldmans?.length) {
      dispatch(fieldmanAsync());
    }
    if (!seasons?.length) {
      dispatch(seasonsAsync());
    }
  }, [dispatch, programId, variety, fieldmans, seasons]);

  const handleClose = () => {
    dispatch(toggleAddFieldModal());
  };

  const handleFieldSelected = (selected: any) => {
    setSelectedField(selected);
  };

  const handleAddField = () => {
    if (selectedField) {
      dispatch(addFieldToOrderThunk(selectedField.id));
      dispatch(toggleAddFieldModal());
    }
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add field to Order</DialogTitle>
      <DialogContent sx={{ width: 800 }}>
        <DialogContentText>
          Select a field to add it to this order
        </DialogContentText>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              getOptionLabel={(option) => option.name}
              options={fieldsCandidates}
              sx={{ marginBlock: 1 }}
              onChange={(_, selected: any) => {
                handleFieldSelected(selected);
              }}
              renderInput={(params) => <TextField {...params} label="Field" />}
            />
          </Grid>
          <Grid item xs={8}>
            <List
              sx={{
                width: 300,
                bgcolor: "background.paper",
                position: "relative",
                overflow: "auto",
                maxHeight: 240,
              }}
            >
              <ListItem sx={{ paddingBlock: 0 }}>
                <ListItemAvatar>
                  <Avatar sx={{ width: 32, height: 32 }}>N</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Name"
                  secondary={selectedField?.name || ""}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem sx={{ paddingBlock: 0 }}>
                <ListItemAvatar>
                  <Avatar sx={{ width: 32, height: 32 }}>G</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Grower"
                  secondary={selectedField?.growerName || ""}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem sx={{ paddingBlock: 0 }}>
                <ListItemAvatar>
                  <Avatar sx={{ width: 32, height: 32 }}>S</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Specie"
                  secondary={selectedField?.specieName || ""}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem sx={{ paddingBlock: 0 }}>
                <ListItemAvatar>
                  <Avatar sx={{ width: 32, height: 32 }}>V</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Variety"
                  secondary={selectedField?.varietyName || ""}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleAddField} disabled={!selectedField}>
          Add Field
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddFieldToOrderDialog;
