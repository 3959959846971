import { Box, Button, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { createGrowerThunk, updateGrowerThunk } from "../maintainer-slice";

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Invalid email'),
  location: yup.string(),
});

const UpsertGrowerModal = ({ grower, onClose, open }) => {

  const dispatch = useDispatch();

  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });
  const handleUpsert = (data) => {
    if (grower) {
      dispatch(updateGrowerThunk({ id: grower.id, name: data.name, email: data.email, location: data.location}));
    } else {
      dispatch(createGrowerThunk({ name: data.name, email: data.email, location: data.location}));
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        {grower ? "Edit" : "Add"} Grower
      </DialogTitle>
    <DialogContent>
      <Box display="flex" flexDirection="column">
     <Controller
        name="name"
        control={control}
        defaultValue={grower?.name || "" }
        render={({ field }) => (
          <TextField
            {...field}
            label="Name"
            variant="outlined"
            margin="normal"
            sx={{ minHeight: 56 }}
            helperText={errors.name?.message}
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        defaultValue={grower?.email || ""}
        render={({ field }) => (
          <TextField
            {...field}
            label="Email"
            variant="outlined"
            margin="normal"
            type="email"
            sx={{ minHeight: 56 }}
            helperText={errors.email?.message}
          />
        )}
      />
      <Controller
        name="location"
        control={control}
        defaultValue={grower?.location || ""}
        render={({ field }) => (
          <TextField
            {...field}
            label="Location"
            variant="outlined"
            margin="normal"
            sx={{ minHeight: 56 }}
            helperText={errors.location?.message}
          />
        )}
      />
      <Button onClick={handleSubmit(handleUpsert)} variant="contained" color="success">
        Save
      </Button>
      </Box>
    </DialogContent>

    </Dialog>
  );
}

UpsertGrowerModal.propTypes = {
  open: PropTypes.bool.isRequired,
  grower: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default UpsertGrowerModal;