import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Chip,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  Typography,
  IconButton,
  Snackbar,
  Alert
} from '@mui/material';
import { ALERT_STATUS_COLORS, ALERT_STATUS_LABELS, LEVEL_STATUS_COLORS, LEVEL_STATUS_LABELS } from 'src/constants/dictionary';
import { useAppDispatch } from 'src/app/hooks';
import { clearFilterGeneralStatus, clearFilterPerformanceStatus, filterFieldsByGeneralStatus, filterFieldsByPerformanceStatus, resetFieldActionIdle } from './field-slice';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchBarField from './FieldsComponents/SearchBarFields';
import DeleteFieldModal from './FieldsComponents/DeleteFieldModal';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteIcon from '@mui/icons-material/Delete';


const FieldsTable = (props) => {
  const {
    count = 0,
    items = [],
    onPageChange = () => { },
    onRowsPerPageChange,
    page = 0,
    rowsPerPage = 0,
    selected = [],
    totalsGeneralStatus = {},
    totalsPerformanceStatus = {},
    handleSearch,
    fieldActionStatus
  } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedStatusFilter, setSelectedStatusFilter] = useState("ALL")
  const [slectedPerformanceFilter, setSelectedPerformanceFilter] = useState("ALL")
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    if (fieldActionStatus === "succeeded" || fieldActionStatus === "failed") {
      setOpenAlert(true);
    }
  }, [fieldActionStatus])

  const getAlertStatusLabel = (status) => {
    return ALERT_STATUS_LABELS[status] || ALERT_STATUS_LABELS.default;
  };
  const getAlertStatusColor = (status) => {
    return ALERT_STATUS_COLORS[status] || ALERT_STATUS_COLORS.default;
  }

  const getPerformanceStatusLabel = (status) => {
    return LEVEL_STATUS_LABELS[status] || LEVEL_STATUS_LABELS.default;
  }

  const getPerformanceStatusColor = (status) => {
    return LEVEL_STATUS_COLORS[status] || LEVEL_STATUS_COLORS.default;
  }


  const handleChangeStatus = (event, newValue) => {
    setSelectedStatusFilter(newValue)
    if (newValue === "ALL") {
      dispatch(clearFilterGeneralStatus())
    } else {
      dispatch(filterFieldsByGeneralStatus(newValue))
    }
  };

  const handleChangePerformance = (event, newValue) => {
    setSelectedPerformanceFilter(newValue)
    if (newValue === "ALL") {
      dispatch(clearFilterPerformanceStatus())
    } else {
      dispatch(filterFieldsByPerformanceStatus(newValue))
    }
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedItem(null);
  };

  const handleDelete = (item) => {
    setSelectedItem(item);
    setOpenDeleteModal(true);
  };

  const handleNavigate = (id) => {
    navigate(`/fieldDetail/${id}`);
  };
  const handleCloseAlert = () => {
    dispatch(resetFieldActionIdle());
    setOpenAlert(false);
  }

  return (
    <Card>
      <Box sx={{ minWidth: 800 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingInline: "10px", display: 'flex', justifyContent: 'space-between' }}>
          <Tabs value={slectedPerformanceFilter} onChange={handleChangePerformance} aria-label="basic tabs example">
            <Tab label={`All (${totalsPerformanceStatus.ALL})`} sx={{ width: 100 }} value="ALL" />
            <Tab label={`Bad (${totalsPerformanceStatus.BAD})`} sx={{ width: 100 }} value="BAD" />
            <Tab label={`Regular (${totalsPerformanceStatus.REGULAR})`} sx={{ width: 100 }} value="REGULAR" />
            <Tab label={`Good (${totalsPerformanceStatus.GOOD})`} sx={{ width: 100 }} value="GOOD" />
          </Tabs>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ flexGrow: 1 }} // Use flexGrow to make the Stack fill the remaining space
          >
            <Typography variant="body2">PERFORMANCE</Typography>
          </Stack>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingInline: "10px", display: 'flex', justifyContent: 'space-between' }} >
          <Tabs value={selectedStatusFilter} onChange={handleChangeStatus} aria-label="basic tabs example">
            <Tab label={`All (${totalsGeneralStatus.ALL})`} sx={{ width: 100 }} value="ALL" />
            <Tab label={`Bad (${totalsGeneralStatus.RED})`} sx={{ width: 100 }} value="RED" />
            <Tab label={`Regular (${totalsGeneralStatus.YELLOW})`} sx={{ width: 100 }} value="YELLOW" />
            <Tab label={`Good (${totalsGeneralStatus.GREEN})`} sx={{ width: 100 }} value="GREEN" />
          </Tabs>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ flexGrow: 1 }} // Use flexGrow to make the Stack fill the remaining space
          >
            <Typography variant="body2">STATUS</Typography>
          </Stack>
        </Box>
   
   <SearchBarField name="field" onSearch={handleSearch}/>
        <Table>
          <TableHead>
            <TableRow>

              <TableCell>
                Name
              </TableCell>
              <TableCell>
                Number
              </TableCell>
              <TableCell>
                Specie
              </TableCell>
              <TableCell>
                Variety
              </TableCell>
              <TableCell>
                Updated
                </TableCell>
              <TableCell>
                Status
              </TableCell>
              <TableCell>
                Performance
              </TableCell>
              <TableCell>
                
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((field) => {
              const isSelected = selected.includes(field.id);

              return (
                <TableRow
                  hover
                  key={field.id}
                  selected={isSelected}
                  // onClick={() => handleNavigate(field.id)}
                >

                  <TableCell>
                    <Stack
                      alignItems="center"
                      direction="row"
                      spacing={2}
                    >
                      {/* <Avatar src={customer.avatar}>
                          {getInitials(customer.name)}
                        </Avatar> */}
                      <Typography variant="subtitle2">
                        {field.name}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>
                    {field.fieldNumber}
                  </TableCell>
                  <TableCell>
                    {field.specieName}
                  </TableCell>
                  <TableCell>
                    {field.varietyName}
                  </TableCell>
                  <TableCell>
                    {field.lastManifoldCreatedDate ? new Date(field.lastManifoldCreatedDate).toLocaleDateString() : '-'}
                  </TableCell>                  
                  <TableCell>
                    <Chip sx={{width: 80}} label={getAlertStatusLabel(field.generalStatus)} color={getAlertStatusColor((field.generalStatus))} />
                  </TableCell>
                  <TableCell>
                    <Chip sx={{width: 80}} label={`${field.performance || '-'}%`} color={getPerformanceStatusColor((field.performanceStatus))} />
                  </TableCell>
                        <TableCell>
                        <Box sx={{display: "flex", flexDirection: "row"}} >
                        <IconButton onClick={() => handleDelete(field)}>
                            <DeleteIcon  />
                          </IconButton>
                          <IconButton onClick={() => handleNavigate(field.id)} >
                            <ArrowForwardIosIcon />
                          </IconButton>
                          </Box>
                        </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>

      <TablePagination
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
     {selectedItem && <DeleteFieldModal  
       onClose={handleCloseDeleteModal}
       onDelete={handleCloseDeleteModal}
       item={selectedItem}
       open={openDeleteModal}
      />}
      <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={openAlert}
                onClose={handleCloseAlert}
                autoHideDuration={3000}
              >
                {fieldActionStatus && (
                  <Alert
                    onClose={handleCloseAlert}
                    severity={
                      fieldActionStatus === "succeeded" ? "success" : "error"
                    }
                    variant="filled"
                    sx={{ width: "100%" }}
                  >
                    {fieldActionStatus === "succeeded"
                      ? "Operation succeeded"
                      : "Operation failed"}
                  </Alert>
                )}
              </Snackbar>
    </Card>
  );
};

FieldsTable.propTypes = {
  count: PropTypes.number,
  items: PropTypes.array,
  onDeselectAll: PropTypes.func,
  onDeselectOne: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onSelectAll: PropTypes.func,
  onSelectOne: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  selected: PropTypes.array,
  totalsGeneralStatus: PropTypes.object,
  totalsPerformanceStatus: PropTypes.object,
  handleSearch: PropTypes.func,
  fieldActionStatus: PropTypes.string
};


export default FieldsTable;