import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/zoom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { manifoldAsync, selectManifoldImages } from "./ManifoldImagesNewSlice";
import classes from "./ManifoldImagesNewScreen.module.css";
import ImageHolder from "../../components/ImageHolder/ImageHolder";

const ManifoldImagesScreen = () => {
  const dispatch = useAppDispatch();
  const manifold = useAppSelector(selectManifoldImages);
  let { id } = useParams();
  useEffect(() => {
    id && dispatch(manifoldAsync(id));
  }, [dispatch, id]);
  return (
    <div className={classes.fullManifoldcontainer}>
      <h1 className={classes.manifoldTitle}>Here are your pictures</h1>
      <Swiper
        navigation={true}
        modules={[Navigation]}
        className={classes.mySwiper}
        slidesPerView={"auto"}
      >
        {manifold.generalLgUrl && (
          <SwiperSlide zoom={true}>
            <div className={classes.swiperContent}>
              <ImageHolder
                src={manifold.generalLgUrl}
                title="General picture"
                landscape
                date={manifold.createdAt}
              />
            </div>
          </SwiperSlide>
        )}
        {manifold.femaleLgUrl && (
          <SwiperSlide>
            <div className={classes.swiperContent}>
              <ImageHolder
                src={manifold.femaleLgUrl}
                title="Female picture"
                date={manifold.createdAt}
              />
            </div>
          </SwiperSlide>
        )}
        {manifold.male1LgUrl && (
          <SwiperSlide>
            <div className={classes.swiperContent}>
              <ImageHolder
                src={manifold.male1LgUrl}
                title="Male picture"
                date={manifold.createdAt}
              />
            </div>
          </SwiperSlide>
        )}
        {manifold.male2LgUrl && (
          <SwiperSlide>
            <div className={classes.swiperContent}>
              <ImageHolder
                src={manifold.male2LgUrl}
                title="Male picture"
                date={manifold.createdAt}
              />
            </div>
          </SwiperSlide>
        )}
        {manifold.male3LgUrl && (
          <SwiperSlide>
            <div className={classes.swiperContent}>
              <ImageHolder
                src={manifold.male3LgUrl}
                title="Male picture"
                date={manifold.createdAt}
              />
            </div>
          </SwiperSlide>
        )}
        {manifold.extraLgUrl && (
          <SwiperSlide>
            <div className={classes.swiperContent}>
              <ImageHolder
                src={manifold.extraLgUrl}
                title="Extra picture"
                date={manifold.createdAt}
              />
            </div>
          </SwiperSlide>
        )}
      </Swiper>
    </div>
  );
};

export default ManifoldImagesScreen;
