
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { createSeasonThunk } from './ProgramConfigSlice';
import { useDispatch } from 'react-redux';

// Define the validation schema using Yup
const schema = yup.object().shape({
  yearRange: yup.string().matches(/^\d{4}-\d{4}$/, 'Invalid format (YYYY-YYYY)').required('Year Range is required'),
});

const SeasonDialog = ({ open, onClose }) => {
  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(createSeasonThunk(data.yearRange));
    onClose(); // Close the dialog after submission
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add new season</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'row' }}>
        {/* <form onSubmit={handleSubmit(onSubmit)} > */}
          <Controller
            name="yearRange"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Year Range (YYYY-YYYY)"
                fullWidth
                error={!!errors.yearRange}
                helperText={errors.yearRange?.message}
              />
            )}
          />
          <Button type="submit" variant="contained" color="primary" sx={{marginInline: 2}} onClick={handleSubmit(onSubmit)}>
            Create
          </Button>
        {/* </form> */}
      </DialogContent>
    </Dialog>
  );
};

SeasonDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default SeasonDialog;
