import { useMemo } from "react";

export const useReportFilter = (
  data,
  clientFilter,
  specieFilter,
  varietyFilter,
  fieldNumberFilter
) => {
  return useMemo(() => {
    if (
      clientFilter.length === 0 &&
      specieFilter.length === 0 &&
      varietyFilter.length === 0 &&
      fieldNumberFilter.length === 0
    ) {
      return data;
    }
    return data.filter((item) => {
      if (
        clientFilter.length > 0 &&
        !clientFilter.some((filter) => filter.id === item.clientId)
      ) {
        return false;
      }
      if (
        specieFilter.length > 0 &&
        !specieFilter.some((filter) => filter.id === item.specieId)
      ) {
        return false;
      }
      if (
        varietyFilter.length > 0 &&
        !varietyFilter.some((filter) => filter.id === item.varietyId)
      ) {
        return false;
      }
      if (
        fieldNumberFilter.length > 0 &&
        !fieldNumberFilter.some((filter) => filter.id === item.fieldNumber)
      ) {
        return false;
      }
      return true;
    });
  }, [data, clientFilter, specieFilter, varietyFilter, fieldNumberFilter]);
};
