import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/zoom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { manifoldAsync, selectManifoldImages } from "./ManifoldImagesSlice";
import classes from "./ManifoldImagesScreen.module.css";
import ImageHolder from "../../components/ImageHolder/ImageHolder";

const ManifoldImagesScreen = () => {
  const dispatch = useAppDispatch();
  const manifold = useAppSelector(selectManifoldImages);
  let { id } = useParams();
  useEffect(() => {
    id && dispatch(manifoldAsync(id));
  }, [dispatch, id]);
  return (
    <div className={classes.fullManifoldcontainer}>
      <h1 className={classes.manifoldTitle}>Here are your pictures</h1>
      <Swiper
        navigation={true}
        modules={[Navigation]}
        className={classes.mySwiper}
        slidesPerView={"auto"}
      >
        {manifold.general_picture_small && (
          <SwiperSlide zoom={true}>
            <div className={classes.swiperContent}>
              <ImageHolder
                src={manifold.general_picture_small}
                title="General picture"
                landscape
                date={manifold.created_at}
              />
            </div>
          </SwiperSlide>
        )}
        {manifold.female && (
          <SwiperSlide>
            <div className={classes.swiperContent}>
              <ImageHolder
                src={manifold.female_sm}
                title="Female picture"
                date={manifold.created_at}
              />
            </div>
          </SwiperSlide>
        )}
        {manifold.male_1_sm && (
          <SwiperSlide>
            <div className={classes.swiperContent}>
              <ImageHolder
                src={manifold.male_1_sm}
                title="Female picture"
                date={manifold.created_at}
              />
            </div>
          </SwiperSlide>
        )}
        {manifold.male_2_sm && (
          <SwiperSlide>
            <div className={classes.swiperContent}>
              <ImageHolder
                src={manifold.male_2_sm}
                title="Female picture"
                date={manifold.created_at}
              />
            </div>
          </SwiperSlide>
        )}
        {manifold.male_3_sm && (
          <SwiperSlide>
            <div className={classes.swiperContent}>
              <ImageHolder
                src={manifold.male_3_sm}
                title="Female picture"
                date={manifold.created_at}
              />
            </div>
          </SwiperSlide>
        )}
        {manifold.extra_sm && (
          <SwiperSlide>
            <div className={classes.swiperContent}>
              <ImageHolder
                src={manifold.extra_sm}
                title="Female picture"
                date={manifold.created_at}
              />
            </div>
          </SwiperSlide>
        )}
      </Swiper>
    </div>
  );
};

export default ManifoldImagesScreen;
