import { useNavigate, useMatch } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import classes from "./SideBar.module.css";
import { useState, useEffect } from "react";
import { routes } from "../routes";
import { useAppDispatch } from "src/app/hooks";
import {
  logOut,
  selectAuthProgramName,
} from "src/screens/LoginScreen/LoginSlice";
import { useSelector } from "react-redux";
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const SideBar = (props) => {
  const dispatch = useAppDispatch();
  const userProgramName = useSelector(selectAuthProgramName);
  const isSupport = useMatch(routes.support);
  const isManifoldImages = useMatch(routes.manifoldImages);
  const isPrivacy = useMatch(routes.privacy);
  const isLogin = useMatch(routes.login);
  const isLanding = useMatch(routes.landing);
  const isOrder = useMatch(routes.orders);
  const navigate = useNavigate();
  const [shouldShowMenus, setShouldShowMenus] = useState(true);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (isSupport || isManifoldImages || isPrivacy || isLogin || isLanding) {
      setShouldShowMenus(false);
    } else {
      if (!shouldShowMenus) {
        setShouldShowMenus(true);
      }
    }
  }, [isSupport, isManifoldImages, isPrivacy, isLogin, isLanding, isOrder]);

  const handleOpenSettings = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    setAnchorEl(null);
    dispatch(logOut());
    navigate("login");
  };
  const itemslist = [
    {
      text: "Orders",
      icon: <AppRegistrationIcon />,
      onclick: () => navigate("orders"),
    },
    {
      text: "Fields",
      icon: <AgricultureIcon />,
      onclick: () => navigate("fields"),
    },
    {
      text: "Last Activity",
      icon: <AccessTimeFilledIcon />,
      onclick: () => navigate("orders"),
    },
    {
      text: "Reports",
      icon: <PermMediaIcon />,
      onclick: () => navigate("report"),
    },
    {
      text: "Stats",
      icon: <QueryStatsIcon />,
      onclick: () => navigate("orders"),
    },
    {
      text: "Maintainer",
      icon: <ManageAccountsIcon />,
      onclick: () => navigate("maintainer"),
    },
    {
      text: "Config",
      icon: <ManageAccountsIcon />,
      onclick: () => navigate("programConfig"),
    },
  ];
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        {!isLanding && (
          <AppBar position="static">
            <Toolbar>
              {shouldShowMenus && (
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                ZEED{userProgramName && ` - ${userProgramName}`}
              </Typography>
              {shouldShowMenus && (
                <>
                  {" "}
                  <Button color="inherit" onClick={handleOpenSettings}>
                    settings
                  </Button>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem>Profile</MenuItem>
                    <MenuItem onClick={handleLogout}>LogOut</MenuItem>
                  </Menu>
                </>
              )}
            </Toolbar>
          </AppBar>
        )}
      </Box>
      <Drawer
        variant="temporary"
        className={classes.drawer}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        PaperProps={{
          style: {
            width: "200px",
          },
        }}
      >
        <DrawerHeader>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            {false ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {itemslist.map((item, index) => {
            const { text, icon, onclick } = item;
            return (
              <ListItemButton key={text} onClick={onclick}>
                <ListItemText primary={text} />
                <ListItemIcon>{icon}</ListItemIcon>
              </ListItemButton>
            );
          })}
        </List>
      </Drawer>
    </>
  );
};
export default SideBar;
