
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment, OutlinedInput, SvgIcon } from '@mui/material';

const SearchBarField = (props) => {
  const { name, onSearch } = props;

  return(
  <Box sx={{ p: 1, display:"flex",flexDirection: "row", justifyContent: "space-between" }}>
    <OutlinedInput
      defaultValue=""
      fullWidth
      placeholder={`Search ${name}`}
      onChange={onSearch}
      startAdornment={(
        <InputAdornment position="start">
          <SvgIcon
            color="action"
            fontSize="small"
          >
            <SearchIcon />
          </SvgIcon>
        </InputAdornment>
      )}
      sx={{ maxWidth: 500 }}
    />
  </Box>)
};
SearchBarField.propTypes = {
  name: PropTypes.string,
  onSearch: PropTypes.func,
};
export default SearchBarField;