import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "src/app/store";
import { fetchFieldsBooksPH, fetchFieldsManifoldsPH } from "src/services/field";

interface ReportState {
  reportList: any[];
  speciesList: any[];
  varietyList: any[];
  clientsList: any[];
  fieldNumberList: any[];
  manifoldReportList: any[];
  status: "idle" | "loading" | "failed" | "succeeded";
  statusManifold: "idle" | "loading" | "failed" | "succeeded";
  error: any;
}

const initialState: ReportState = {
  reportList: [],
  speciesList: [],
  varietyList: [],
  clientsList: [],
  fieldNumberList: [],
  manifoldReportList: [],
  status: "idle",
  statusManifold: "idle",
  error: null,
};

// Thunk to fetch reports
export const fetchReportsThunk = createAsyncThunk(
  "report/fetchReports",
  async () => {
    try {
      const response = await fetchFieldsBooksPH(); // Make the API call to fetch reports using the programId
      return response.data; // Assuming the response contains the list of reports
    } catch (error) {
      throw new Error("Failed to fetch reports");
    }
  }
);

// Thunk to fetch manifold reports, receives list of fieldIds
export const fetchManifoldReportsThunk = createAsyncThunk(
  "report/fetchManifoldReports",
  async (fieldIds: number[]) => {
    try {
      const response = await fetchFieldsManifoldsPH(fieldIds); // Make the API call to fetch reports using the programId
      return response.data; // Assuming the response contains the list of reports
    } catch (error) {
      throw new Error("Failed to fetch reports");
    }
  }
);

const reportSlice = createSlice({
  name: "report",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReportsThunk.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchReportsThunk.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.reportList = action.payload.fields;
        state.speciesList = action.payload.species.map((item: any) => ({
          id: item.id,
          label: item.name,
        }));
        state.varietyList = action.payload.varieties.map((item: any) => ({
          id: item.id,
          label: item.name,
        }));
        state.clientsList = action.payload.clients.map((item: any) => ({
          id: item.id,
          label: item.name,
        }));
        state.fieldNumberList = action.payload.fieldNumbers.map(
          (item: any) => ({
            id: item,
            label: item.toString(),
          })
        );
      })
      .addCase(fetchReportsThunk.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchManifoldReportsThunk.pending, (state) => {
        state.statusManifold = "loading";
      })
      .addCase(fetchManifoldReportsThunk.fulfilled, (state, action) => {
        state.statusManifold = "succeeded";
        state.manifoldReportList = action.payload;
      })
      .addCase(fetchManifoldReportsThunk.rejected, (state, action) => {
        state.statusManifold = "failed";
        state.error = action.error.message;
      });
  },
});

export default reportSlice.reducer;

export const selectReportState = (state: RootState) => state.report;
export const selectReportList = (state: RootState) => state.report.reportList;
export const selectReportStatus = (state: RootState) => state.report.status;
