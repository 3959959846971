import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import {
  loginUser,
  refreshThunk,
  selectAuthState,
} from "src/screens/LoginScreen/LoginSlice";

const useAuth = () => {
  const { user, isLoading, error } = useAppSelector(selectAuthState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!user && !isLoading) {
      const { token, user, expirationDate, refresh_token } = getStorageItems();
      if (token && user && expirationDate && refresh_token) {
        const currentDate = new Date();
        // if (currentDate > expirationDate) {
        dispatch(refreshThunk(refresh_token));
        // } else {
        // dispatch(loginUser({ user, token, refresh_token }));
        // }
      }
    }
  }, [user, dispatch]);

  const getStorageItems = () => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    const expirationDateStr = localStorage.getItem("expirationDate");
    const refresh_token = localStorage.getItem("refreshToken");
    const expirationDate = expirationDateStr
      ? new Date(expirationDateStr)
      : null;

    return { token, user, expirationDate, refresh_token };
  };

  return { user, isLoading, error };
};

export default useAuth;
