import { Button, Card, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ReportFilterComponent from "./ReportFilterBar";
import { BOOK_FORM_PH_WEB } from "src/zeed-shared";
import { fetchManifoldReportsThunk, selectReportState } from "../report-slice";
import { useCallback, useEffect, useState } from "react";
import { useReportFilter } from "../ReportUtils/useReportFilter";
import { useReportPagination } from "../ReportUtils/useReportPagination";
import { generatePPT } from "../ReportUtils/pptBuilder";

const ReportTable = () => {
  const dispatch = useDispatch();
  const [clientFilter, setClientFilter] = useState([]);
  const [specieFilter, setSpecieFilter] = useState([]);
  const [varietyFilter, setVarietyFilter] = useState([]);
  const [numberFilter, setNumberFilter] = useState([]);
  const [loadingPPT, setLoadingPPT] = useState(false)
  const [clickedGenerate, setClickedGenerate] = useState(false);
  const { reportList, manifoldReportList } = useSelector(selectReportState)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const contentFiltered = useReportFilter(reportList, clientFilter, specieFilter, varietyFilter, numberFilter);
  const contentPage = useReportPagination(contentFiltered, page, rowsPerPage);



  const formatTableValue = (value, definition) => {
    if (value === null || value === undefined) {
      return "-";
    }
    if (definition.type === "date") {
      return new Date(value).toLocaleDateString();
    }
    return value;

  }
  const handleClientFilter = (event, value) => {
    setClientFilter(value);
  };

  const handleSpecieFilter = (event, value) => {
    setSpecieFilter(value);
  }

  const handleVarietyFilter = (event, value) => {
    setVarietyFilter(value);
  }

  const handleNumberFilter = (event, value) => {
    setNumberFilter(value);
  }

  const handleGenerateManifoldReport = () => {
    setClickedGenerate(true)
    dispatch(fetchManifoldReportsThunk(contentFiltered.map((item) => item.id)))
  }

  const handlePageChange = useCallback((event, value) => {
    setPage(value);
  }, []);

  const handleRowsPerPageChange = useCallback((event) => {
    setRowsPerPage(event.target.value);
  }, []);

  const handleGeneratePPT =useCallback( async ()=>{
    await generatePPT(manifoldReportList, clientFilter)
    setClickedGenerate(false)
    setLoadingPPT(false)
  }, [manifoldReportList, clientFilter])

  useEffect(() => { 
    if(manifoldReportList.length > 0 && clientFilter.length > 0 && clickedGenerate){
      setLoadingPPT(true)
      handleGeneratePPT()
    }
  },[manifoldReportList, dispatch, clientFilter, clickedGenerate, handleGeneratePPT])

  return (<Card>
    <ReportFilterComponent 
    clientFilter={clientFilter}
    specieFilter={specieFilter}
    varietyFilter={varietyFilter}
    numberFilter={numberFilter}
    handleClientFilter={handleClientFilter}
    handleSpecieFilter={handleSpecieFilter}
    handleVarietyFilter={handleVarietyFilter}
    handleNumberFilter={handleNumberFilter}
    handleGenerate={handleGenerateManifoldReport}
    loadingGenerate={loadingPPT}

    />
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
          <TableCell colSpan={4}>
                data
              </TableCell>
            {BOOK_FORM_PH_WEB.map((item, index) => (
              <TableCell key={index} colSpan={item.size}>
                {item.title}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
          <TableCell  sx={{ minWidth: 100 }}>
                  <Typography variant="body2">
                    Client

                  </Typography>
                </TableCell>
                <TableCell  sx={{ minWidth: 100 }}>
                  <Typography variant="body2">
                    Specie

                  </Typography>
                </TableCell>
                <TableCell  sx={{ minWidth: 100 }}>
                  <Typography variant="body2">
                    Variety

                  </Typography>
                </TableCell>
                <TableCell  sx={{ minWidth: 100 }}>
                  <Typography variant="body2">
                    Number

                  </Typography>
                </TableCell>
            {BOOK_FORM_PH_WEB.map((item, index) => (
              item.values.map((value, index) => (
                <TableCell key={index} sx={{ minWidth: 100 }}>
                  <Typography variant="body2">
                    {value.label}

                  </Typography>
                </TableCell>
              ))
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {contentPage && contentPage.map((row, index) => (
            <TableRow key={index}>
              <TableCell >
                    {row.clientName || ""}
                  </TableCell>
                  <TableCell >
                    {row.specieName || ""}
                  </TableCell>
                  <TableCell >
                    {row.varietyName || ""}
                  </TableCell>
                  <TableCell >
                    {row.fieldNumber || ""}
                  </TableCell>
              {BOOK_FORM_PH_WEB.map((item, index) => (
                item.values.map((value, index) => (
                  <TableCell key={index}>
                    {row.book ? formatTableValue(row.book[value.key], value): "-"}
                  </TableCell>
                ))
              ))}
            </TableRow>
          ))}

        </TableBody>
      </Table>

    </TableContainer>
    <TablePagination
      component="div"
      count={contentFiltered?.length || 0}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={[5, 10, 25]}
    />
  </Card>)
}

export default ReportTable;