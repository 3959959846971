// import { Button, TextField } from "@mui/material";
// import classes from "./LoginScreen.module.css";
// import user from "./resources/user.png";

// const LoginScreen = () => {
//   return (
//     <div className={classes.fullLogincontainer}>
//       <div className={classes.loginContainer}>
//         <div className={classes.Logo}>
//           <img className={classes.MyImg} src={user} alt="user" />
//         </div>
//         <div className={classes.loginTitleContainer}>
//           <h1 className={classes.title}>Welcome to Zeed!</h1>
//         </div>

//         <div className={classes.formContainer}>
//           <form className={classes.form}>
//             <TextField
//               id="outlined-basic"
//               label="Username"
//               variant="outlined"
//               margin="normal"
//               fullWidth
//             />
//             <TextField
//               id="outlined-basic"
//               label="Password"
//               variant="outlined"
//               type="password"
//               fullWidth
//               sx={{ fontSize: 14 }}
//             />
//             <div className={classes.buttonContainer}>
//               <Button
//                 variant="contained"
//                 fullWidth
//                 size="large"
//                 sx={{ fontSize: 14 }}
//               >
//                 Log In
//               </Button>
//               {/* <Button btnType='Succes' clicked={login}> Log In</Button> */}
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LoginScreen;

import React, { useEffect, useState } from "react";
import { TextField, Button, Container, Typography, Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { loginThunk, selectAuthState } from "./LoginSlice";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "src/hooks/authHook";
import Loader from "../../components/Loader/Loader";

const LoginScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const { user } = useAppSelector(selectAuthState);
  const { user, isLoading } = useAuth();

  useEffect(() => {
    if (user) {
      if (location?.state?.location?.pathname) {
        navigate(
          location.state.location.pathname + location.state.location.search,
          { replace: true }
        );
      } else {
        navigate("/orders", { replace: true });
      }
    }
  }, [user, dispatch, navigate]);
  const handleLogin = () => {
    dispatch(loginThunk({ username: email, password: password }));
  };

  return (
    <>
      {!isLoading && !user ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100vh"
          bgcolor="background.default"
        >
          <Container maxWidth="xs">
            <Box bgcolor="white" padding={4} borderRadius={8} boxShadow={4}>
              <Typography variant="h4" align="center" gutterBottom>
                Login
              </Typography>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
              />
              <TextField
                label="Password"
                variant="outlined"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                margin="normal"
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleLogin}
                size="large"
              >
                Login
              </Button>
            </Box>
          </Container>
        </Box>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default LoginScreen;
