import Screen from "../../components/Screen/Screen";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Alert,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import {
  clientsAsync,
  ordersAsync,
  resetIdleAction,
  selectOrdersState,
  speciesAsync,
} from "./OrdersSlice";
import CreateOrderDialog from "./CreateOrderDialog";
import AddFieldmanToOrderDialog from "./OrderComponents/AddFieldmanToOrderDialog";
import { toggleModal } from "./CreateOrderSlice";
import {
  fetchFieldsForOrderThunk,
  selectOrder,
  toggleAddFieldModal,
} from "./add-field-to-order-slice";
import AddFieldToOrderDialog from "./AddFieldToOrderDialog";
import {
  setFormValueSimpleFieldman,
  toggleModalFieldman,
} from "./AddFieldmanToOrderSlice";
import { useOrderFilter } from "./OrderUtils/useOrderFilter";
import { usePagination } from "src/utils/usePagination";
import SearchBarOrder from "./OrderComponents/SearchBarOrder";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOrderModal from "./OrderComponents/DeleteOrderModal";

const OrdersScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orders, clients, species, status, orderActionStatus } =
    useAppSelector(selectOrdersState);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [clientFilter, setClientFilter] = useState<any | null>(null);
  const [specieFilter, setSpecieFilter] = useState<any | null>(null);
  const [filteredOrders, setFilteredOrders] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>("");
  const content = useOrderFilter(filteredOrders, searchText);
  const contentPage = usePagination(content, page, rowsPerPage);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    dispatch(ordersAsync());
    dispatch(clientsAsync());
    dispatch(speciesAsync());
  }, [dispatch]);

  useEffect(() => {
    if (orderActionStatus === "succeeded") {
      dispatch(ordersAsync());
      setOpenAlert(true);
    }
    if (orderActionStatus === "failed") {
      setOpenAlert(true);
    }
  }, [orderActionStatus, dispatch]);

  const filterOrderHandler = useCallback(() => {
    return orders.filter((item: any) => {
      // Check if both filters are null
      if (clientFilter === null && specieFilter === null) {
        return true; // Include all elements
      }

      // Check if either filter is null or matches the item's corresponding field value
      return (
        (clientFilter === null || item.client.id === clientFilter.id) &&
        (specieFilter === null || item.specie.id === specieFilter.id)
      );
    });
  }, [clientFilter, specieFilter, orders]);

  useEffect(() => {
    setFilteredOrders(filterOrderHandler());
  }, [clientFilter, specieFilter, orders, filterOrderHandler]);

  const handleOpenModal = () => {
    dispatch(toggleModal());
  };
  const handleAddField = (orderId: number) => {
    dispatch(selectOrder(orderId));
    dispatch(fetchFieldsForOrderThunk());
    dispatch(toggleAddFieldModal());
  };
  const handleAddFieldmanToOrder = (orderId: any) => {
    dispatch(setFormValueSimpleFieldman({ name: "orderId", value: orderId }));
    dispatch(toggleModalFieldman());
  };
  const handleClientFilter = (
    event: React.SyntheticEvent<{}>,
    newValue: {} | null
  ) => {
    setClientFilter(newValue);
  };
  const handleSpecieFilter = (
    event: React.SyntheticEvent<{}>,
    newValue: {} | null
  ) => {
    setSpecieFilter(newValue);
  };

  const handlePageChange = useCallback((event: any, value: any) => {
    setPage(value);
  }, []);

  const handleRowsPerPageChange = useCallback((event: any) => {
    setRowsPerPage(event.target.value);
  }, []);
  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
  };

  const handleCloseDeleteModal = () => {
    setSelectedItem(null);
    setOpenDeleteModal(false);
  };
  const handleOpenDeleteModal = (item: any) => {
    setSelectedItem(item);
    setOpenDeleteModal(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
    dispatch(resetIdleAction());
  };

  return (
    <Screen padding="m">
      <CreateOrderDialog />
      <AddFieldmanToOrderDialog />
      <AddFieldToOrderDialog />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={10}>
            <Typography variant="h4">Orders</Typography>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              color="success"
              onClick={handleOpenModal}
            >
              New order
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={clients}
              onChange={handleClientFilter}
              renderInput={(params) => <TextField {...params} label="Client" />}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={species}
              onChange={handleSpecieFilter}
              renderInput={(params) => <TextField {...params} label="Specie" />}
            />
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper} style={{ maxHeight: 500 }}>
              <SearchBarOrder name="order" onSearch={handleSearch} />
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: 16 }}>Client</TableCell>
                    <TableCell style={{ fontSize: 16 }}>Specie</TableCell>
                    <TableCell style={{ fontSize: 16 }}>Variety</TableCell>
                    <TableCell style={{ fontSize: 16 }}>Fieldman</TableCell>
                    <TableCell style={{ fontSize: 16 }}>
                      Field numbers
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!contentPage.length &&
                    contentPage.map((ord: any, key: any) => (
                      <TableRow
                        key={key}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {/* <Text size="s">{point.created_at.slice(0, 10)}</Text> */}
                          {ord.client.name}
                        </TableCell>
                        <TableCell>{ord.specie.name}</TableCell>
                        <TableCell>{ord.variety.name}</TableCell>
                        <TableCell
                          onClick={() => {
                            handleAddFieldmanToOrder(ord.id);
                          }}
                        >
                          {ord.user?.name || "pending"}
                        </TableCell>
                        <TableCell sx={{ maxWidth: 100 }}>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            {(ord.fields &&
                              ord.fields.length &&
                              ord.fields.map(
                                (item: any, index: any) =>
                                  item.fieldNumber && (
                                    <Chip
                                      key={index}
                                      label={item.fieldNumber}
                                    />
                                  )
                              )) ||
                              ""}
                          </Stack>
                        </TableCell>
                        <TableCell>
                          {/* <Button
                            variant="outlined"
                            size="large"
                            onClick={() => {
                              handleAddField(ord.id);
                            }}
                          >
                            Add fields
                          </Button> */}
                          {/* <Button
                            variant="outlined"
                            size="large"
                            onClick={() => {
                              navigate(`/inspectionReport/${ord.id}`);
                            }}
                          >
                            Quality
                          </Button> */}
                          {/* <Button
                            variant="outlined"
                            size="large"
                            onClick={() => {
                              navigate(`/orderDetails/${ord.id}`);
                            }}
                          >
                            See details
                          </Button> */}
                          <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <IconButton
                              onClick={() => {
                                handleAddField(ord.id);
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                handleOpenDeleteModal(ord);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={content?.length || 0}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
              {selectedItem && (
                <DeleteOrderModal
                  onClose={handleCloseDeleteModal}
                  onDelete={handleCloseDeleteModal}
                  item={selectedItem}
                  open={openDeleteModal}
                />
              )}
              <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={openAlert}
                onClose={handleCloseAlert}
                autoHideDuration={3000}
              >
                {orderActionStatus && (
                  <Alert
                    onClose={handleCloseAlert}
                    severity={
                      orderActionStatus === "succeeded" ? "success" : "error"
                    }
                    variant="filled"
                    sx={{ width: "100%" }}
                  >
                    {orderActionStatus === "succeeded"
                      ? "Operation succeeded"
                      : "Operation failed"}
                  </Alert>
                )}
              </Snackbar>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </Screen>
  );
};

export default OrdersScreen;
