import { Box, Button, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { createSpecieThunk, updateSpecieThunk } from "../maintainer-slice";

const schema = yup.object().shape({
  name: yup.string().required('Name is required')
});

const UpsertSpecieModal = ({ specie, onClose, open }) => {
  
    const dispatch = useDispatch();
  
    const { control, handleSubmit, formState: { errors } } = useForm({
      resolver: yupResolver(schema),
    });
    const handleUpsert = (data) => {
      if (specie) {
        dispatch(updateSpecieThunk({ id: specie.id, name: data.name}));
      } else {
        dispatch(createSpecieThunk({ name: data.name}));
      }
      onClose();
    };
  
    return (
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogTitle>
          {specie ? "Edit" : "Add"} Specie
        </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
      <Controller
          name="name"
          control={control}
          defaultValue={specie?.name || "" }
          render={({ field }) => (
            <TextField
              {...field}
              label="Name"
              variant="outlined"
              margin="normal"
              sx={{ minHeight: 56 }}
              helperText={errors.name?.message}
            />
          )}
        />
        <Button
          onClick={handleSubmit(handleUpsert)}
          variant="contained"
          sx={{ mt: 2 }}
        >
          Save
        </Button>
      </Box>
      </DialogContent>
      </Dialog>
    );
  };

UpsertSpecieModal.propTypes = {
  specie: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default UpsertSpecieModal;