export const HEADER_LABELS_GROWER = ["name", "location", "email"];
export const KEYS_GROWERS = ["name", "location", "email"];
export const HEADER_LABELS_FIELDMAN = ["name", "email", "phone number"];
export const KEYS_FIELDMAN = ["name", "email", "phoneNumber"];
export const HEADER_LABELS_SPECIE = ["name"];
export const KEYS_SPECIE = ["name"];
export const HEADER_LABELS_VARIETY = ["name"];
export const KEYS_VARIETY = ["name"];
export const HEADER_LABELS_CLIENT = ["name"];
export const KEYS_CLIENT = ["name"];
