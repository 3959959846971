import { useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Grid, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import {
  createOrderAsync,
  resetFormValue,
  selectCreateOrderState,
  setFormValueSimple,
  toggleModal,
} from "./CreateOrderSlice";
import {
  fieldmanAsync,
  programDefaultAsync,
  seasonsAsync,
  selectOrdersState,
  selectProgramDefault,
  varietyAsync,
} from "./OrdersSlice";
import { selectAuthState } from "../LoginScreen/LoginSlice";

const CreateOrderDialog = () => {
  const dispatch = useAppDispatch();
  const { open, isLoading } = useAppSelector(selectCreateOrderState);
  const programDefault = useAppSelector(selectProgramDefault);
  const { clients, species, variety, fieldmans } =
    useAppSelector(selectOrdersState);
  const { programId } = useAppSelector(selectAuthState);

  useEffect(() => {
    if (!variety?.length) {
      dispatch(varietyAsync());
    }
    if (!fieldmans?.length) {
      dispatch(fieldmanAsync());
    }
    // if (!seasons?.length) {
    //   dispatch(seasonsAsync());
    // }
    dispatch(programDefaultAsync());
  }, [dispatch, programId, variety, fieldmans]);

  const handleClose = () => {
    dispatch(toggleModal());
  };

  const handleChange = (name: any, value: any) => {
    if (value) {
      dispatch(setFormValueSimple({ name, value: value.id }));
    } else {
      dispatch(resetFormValue({ name }));
    }
  };

  const handleSave = () => {
    dispatch(createOrderAsync());
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{`Create Client Order ${
        programDefault?.season?.name || ""
      }`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Fill all of the items below to create a new order
        </DialogContentText>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {/* <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={seasons}
              onChange={(_, v: any) => {
                handleChange("seasonId", v);
              }}
              sx={{ marginBlock: 1 }}
              renderInput={(params) => <TextField {...params} label="Season" />}
            /> */}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={clients}
              onChange={(_, v: any) => {
                handleChange("clientId", v);
              }}
              sx={{ marginBlock: 1 }}
              renderInput={(params) => <TextField {...params} label="Client" />}
            />
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={species}
              onChange={(_, v: any) => {
                handleChange("specieId", v);
              }}
              sx={{ marginBlock: 1 }}
              renderInput={(params) => <TextField {...params} label="Specie" />}
            />
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={variety}
              onChange={(_, v: any) => {
                handleChange("varietyId", v);
              }}
              sx={{ marginBlock: 1 }}
              renderInput={(params) => (
                <TextField {...params} label="Variety" />
              )}
            />
            {/* <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={variety}
              sx={{ marginBlock: 1 }}
              renderInput={(params) => (
                <TextField {...params} label="Variety male" />
              )}
            /> */}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={fieldmans}
              sx={{ marginBlock: 1 }}
              onChange={(_, v: any) => {
                handleChange("fieldmanId", v);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Fieldman" />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={1}>
              {/* <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="plantsFemale"
                  label="Plants female"
                  type="number"
                  fullWidth
                  variant="standard"
                />
              </Grid> */}
              {/* <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="plantsMale"
                  label="Plants male"
                  type="number"
                  fullWidth
                  variant="standard"
                />
              </Grid> */}
            </Grid>

            {/* <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="ratioUpper"
                  label="Ratio Upper"
                  type="number"
                  fullWidth
                  variant="standard"
                />
              </Grid> */}
            {/* <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="ratioLower"
                  label="Ratio Lower"
                  type="number"
                  fullWidth
                  variant="standard"
                />
              </Grid>
            </Grid>
            <TextField
              autoFocus
              margin="dense"
              id="targetKg"
              label="Target kg"
              type="number"
              fullWidth
              variant="standard"
            />
            <TextField
              autoFocus
              margin="dense"
              id="base"
              label="Base"
              type="number"
              fullWidth
              variant="standard"
            />
            <TextField
              autoFocus
              margin="dense"
              id="surface"
              label="Required surface"
              type="number"
              fullWidth
              variant="standard"
            /> */}
          </Grid>
          {isLoading === "failed" && (
            <Typography variant="body2" color="error">
              Error saving order
            </Typography>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSave}>Create</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateOrderDialog;
