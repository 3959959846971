import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "src/app/store";
import { createFieldman, fetchFieldmansNoType } from "src/services/fieldman";
import {
  createClient,
  createGrower,
  createSpecie,
  createVariety,
  deleteClient,
  deleteGrower,
  deleteSpecie,
  deleteVarity,
  editClient,
  editGrower,
  editSpecie,
  editVariety,
  getClients,
  getGrowers,
  getSpecies,
  getVarieties,
} from "src/services/maintainer";

interface MaintainerState {
  content: [{}] | null;
  isLoading: boolean;
  error: string | null;
  clientStatus: "idle" | "loading" | "succeeded" | "failed";
  growerStatus: "idle" | "loading" | "succeeded" | "failed";
  specieStatus: "idle" | "loading" | "succeeded" | "failed";
  varietyStatus: "idle" | "loading" | "succeeded" | "failed";
  fieldmanStatus: "idle" | "loading" | "succeeded" | "failed";
}
const initialState: MaintainerState = {
  content: null,
  isLoading: false,
  error: null,
  clientStatus: "idle",
  growerStatus: "idle",
  specieStatus: "idle",
  varietyStatus: "idle",
  fieldmanStatus: "idle",
};

//CLIENT THUNKS
export const getClientsThunk = createAsyncThunk(
  "maintainer/getClients",
  async (_, { getState }) => {
    const programId = (getState() as RootState).auth.programId;
    const response = await getClients(programId);
    return { content: response.data };
  }
);

export const createClientThunk = createAsyncThunk(
  "maintainer/createClient",
  async (payload: { name: string }, { getState }) => {
    const programId = (getState() as RootState).auth.programId;
    const response = await createClient(payload.name, programId);

    return { id: response.data, name: payload.name };
  }
);

export const updateClientsThunk = createAsyncThunk(
  "maintainer/updateClient",
  async (payload: { id: number; name: string }) => {
    await editClient(payload.id, payload.name);
  }
);

export const deleteClientThunk = createAsyncThunk(
  "maintainer/deleteClient",
  async (id: number) => {
    await deleteClient(id);
  }
);

//SPECIE THUNKS
export const getSpeciesThunk = createAsyncThunk(
  "maintainer/getSpecies",
  async (_, { getState }) => {
    const programId = (getState() as RootState).auth.programId;
    const response = await getSpecies(programId);
    return { content: response.data };
  }
);

export const createSpecieThunk = createAsyncThunk(
  "maintainer/createSpecie",
  async (payload: { name: string }, { getState }) => {
    const programId = (getState() as RootState).auth.programId;
    const response = await createSpecie(payload.name, programId);

    return { id: response.data, name: payload.name };
  }
);

export const updateSpecieThunk = createAsyncThunk(
  "maintainer/updateSpecie",
  async (payload: { id: number; name: string }) => {
    await editSpecie(payload.id, payload.name);
  }
);

export const deleteSpecieThunk = createAsyncThunk(
  "maintainer/deleteSpecie",
  async (id: number) => {
    await deleteSpecie(id);
  }
);

//VARIETY THUNKS

export const getVarietiesThunk = createAsyncThunk(
  "maintainer/getVarieties",
  async (_, { getState }) => {
    const programId = (getState() as RootState).auth.programId;
    const response = await getVarieties(programId);
    return { content: response.data };
  }
);

export const createVarietyThunk = createAsyncThunk(
  "maintainer/createVariety",
  async (payload: { name: string }, { getState }) => {
    const programId = (getState() as RootState).auth.programId;
    const response = await createVariety(payload.name, programId);

    return { id: response.data, name: payload.name };
  }
);

export const updateVarietyThunk = createAsyncThunk(
  "maintainer/updateVariety",
  async (payload: { id: number; name: string }) => {
    await editVariety(payload.id, payload.name);
  }
);

export const deleteVarietyThunk = createAsyncThunk(
  "maintainer/deleteVariety",
  async (id: number) => {
    await deleteVarity(id);
  }
);

//GROWER THUNKS

export const getGrowersThunk = createAsyncThunk(
  "maintainer/getGrowers",
  async (_, { getState }) => {
    const programId = (getState() as RootState).auth.programId;
    const response = await getGrowers(programId);
    return { content: response.data };
  }
);

export const createGrowerThunk = createAsyncThunk(
  "maintainer/createGrower",
  async (
    payload: { name: string; email: string; location: string },
    { getState }
  ) => {
    const programId = (getState() as RootState).auth.programId;
    const response = await createGrower(
      payload.name,
      programId,
      payload.email,
      payload.location
    );

    return {
      id: response.data,
      name: payload.name,
      email: payload.email,
      location: payload.location,
    };
  }
);

export const updateGrowerThunk = createAsyncThunk(
  "maintainer/updateGrower",
  async (
    payload: { id: number; name: string; email: string; location: string },
    { getState }
  ) => {
    const programId = (getState() as RootState).auth.programId;
    const response = await editGrower(
      payload.id,
      payload.name,
      programId,
      payload.email,
      payload.location
    );

    return {
      id: response.data,
      name: payload.name,
      email: payload.email,
      location: payload.location,
    };
  }
);

export const deleteGrowerThunk = createAsyncThunk(
  "maintainer/deleteGrower",
  async (id: number) => {
    await deleteGrower(id);
  }
);

export const getFieldmanThunk = createAsyncThunk(
  "maintainer/getFieldman",
  async (_, { getState }) => {
    const programId = (getState() as RootState).auth.programId;
    const response = await fetchFieldmansNoType(programId);
    return { content: response };
  }
);

export const createFieldmanThunk = createAsyncThunk(
  "maintainer/createFieldman",
  async (
    payload: {
      name: string;
      email: string;
      phone: string;
      qualityInspector: boolean;
    },
    { getState }
  ) => {
    const programId = (getState() as RootState).auth.programId;
    const roles = ["ROLE_USER"];
    if (payload.qualityInspector) roles.push("ROLE_QUALITY_CONTROL");
    const response = await createFieldman(
      payload.name,
      programId,
      payload.email,
      payload.phone,
      roles
    );
    return { id: response, name: payload.name };
  }
);

export const maintainerSlice = createSlice({
  name: "maintainer",
  initialState,
  reducers: {
    resetIdleGrower: (state) => {
      state.growerStatus = "idle";
    },
    resetIdleClient: (state) => {
      state.clientStatus = "idle";
    },
    resetIdleSpecie: (state) => {
      state.specieStatus = "idle";
    },
    resetIdleVariety: (state) => {
      state.varietyStatus = "idle";
    },
    resetIdleFieldman: (state) => {
      state.fieldmanStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      //CLIENT GET
      .addCase(getClientsThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClientsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.content = action.payload.content;
      })
      .addCase(getClientsThunk.rejected, (state, action) => {
        state.isLoading = false;
      })
      //CLIENT CREATE
      .addCase(createClientThunk.pending, (state) => {
        state.clientStatus = "loading";
      })
      .addCase(createClientThunk.fulfilled, (state, action) => {
        state.clientStatus = "succeeded";
        state.isLoading = false;
        state.content?.push({
          id: action.payload.id,
          name: action.payload.name,
        });
      })
      .addCase(createClientThunk.rejected, (state, action) => {
        state.clientStatus = "failed";
        state.isLoading = false;
      })
      //CLIENT UPDATE
      .addCase(updateClientsThunk.pending, (state) => {
        state.clientStatus = "loading";
      })

      .addCase(updateClientsThunk.rejected, (state, action) => {
        state.clientStatus = "failed";
      })
      .addCase(updateClientsThunk.fulfilled, (state, action) => {
        state.clientStatus = "succeeded";
      })
      //CLIENT DELETE
      .addCase(deleteClientThunk.pending, (state) => {
        state.clientStatus = "loading";
      })
      .addCase(deleteClientThunk.rejected, (state, action) => {
        state.clientStatus = "failed";
      })
      .addCase(deleteClientThunk.fulfilled, (state, action) => {
        state.clientStatus = "succeeded";
      })

      //GROWER GET
      .addCase(getGrowersThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGrowersThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.content = action.payload.content;
      })
      .addCase(getGrowersThunk.rejected, (state, action) => {
        state.isLoading = false;
      })
      //GROWER CREATE
      .addCase(createGrowerThunk.pending, (state) => {
        state.growerStatus = "loading";
      })
      .addCase(createGrowerThunk.fulfilled, (state, action) => {
        state.growerStatus = "succeeded";
        state.content?.push({
          id: action.payload.id,
          name: action.payload.name,
          email: action.payload.email,
          location: action.payload.location,
        });
      })
      .addCase(createGrowerThunk.rejected, (state, action) => {
        state.growerStatus = "failed";
      })

      //GROWER UPDATE
      .addCase(updateGrowerThunk.pending, (state) => {
        state.growerStatus = "loading";
      })
      .addCase(updateGrowerThunk.fulfilled, (state, action) => {
        state.growerStatus = "succeeded";
      })
      .addCase(updateGrowerThunk.rejected, (state, action) => {
        state.growerStatus = "failed";
      })

      //GROWER DELETE
      .addCase(deleteGrowerThunk.pending, (state) => {
        state.growerStatus = "loading";
      })
      .addCase(deleteGrowerThunk.rejected, (state, action) => {
        state.growerStatus = "failed";
      })
      .addCase(deleteGrowerThunk.fulfilled, (state, action) => {
        state.growerStatus = "succeeded";
      })

      //FIELDMAN GET
      .addCase(getFieldmanThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFieldmanThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.content = action.payload.content;
      })
      .addCase(getFieldmanThunk.rejected, (state, action) => {
        state.isLoading = false;
      })
      //FIELDMAN CREATE
      .addCase(createFieldmanThunk.pending, (state) => {
        state.fieldmanStatus = "loading";
      })
      .addCase(createFieldmanThunk.fulfilled, (state, action) => {
        state.fieldmanStatus = "succeeded";
        state.content?.push({
          id: action.payload.id,
          name: action.payload.name,
        });
      })
      .addCase(createFieldmanThunk.rejected, (state, action) => {
        state.fieldmanStatus = "failed";
        state.isLoading = false;
      })

      //SPECIE GET
      .addCase(getSpeciesThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSpeciesThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.content = action.payload.content;
      })
      .addCase(getSpeciesThunk.rejected, (state, action) => {
        state.isLoading = false;
      })
      //SPECIE CREATE

      .addCase(createSpecieThunk.rejected, (state, action) => {
        state.specieStatus = "failed";
      })
      .addCase(createSpecieThunk.pending, (state) => {
        state.specieStatus = "loading";
      })
      .addCase(createSpecieThunk.fulfilled, (state, action) => {
        state.specieStatus = "succeeded";
        state.content?.push({
          id: action.payload.id,
          name: action.payload.name,
        });
      })
      //SPECIE UPDATE
      .addCase(updateSpecieThunk.pending, (state) => {
        state.specieStatus = "loading";
      })
      .addCase(updateSpecieThunk.fulfilled, (state, action) => {
        state.specieStatus = "succeeded";
      })
      .addCase(updateSpecieThunk.rejected, (state, action) => {
        state.specieStatus = "failed";
      })
      //SPECIE DELETE
      .addCase(deleteSpecieThunk.pending, (state) => {
        state.specieStatus = "loading";
      })
      .addCase(deleteSpecieThunk.rejected, (state, action) => {
        state.specieStatus = "failed";
      })
      .addCase(deleteSpecieThunk.fulfilled, (state, action) => {
        state.specieStatus = "succeeded";
      })

      //VARIETY GET
      .addCase(getVarietiesThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVarietiesThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.content = action.payload.content;
      })
      .addCase(getVarietiesThunk.rejected, (state, action) => {
        state.isLoading = false;
      })
      //VARIETY CREATE
      .addCase(createVarietyThunk.pending, (state) => {
        state.varietyStatus = "loading";
      })
      .addCase(createVarietyThunk.fulfilled, (state, action) => {
        state.varietyStatus = "succeeded";
        state.content?.push({
          id: action.payload.id,
          name: action.payload.name,
        });
      })
      .addCase(createVarietyThunk.rejected, (state, action) => {
        state.varietyStatus = "failed";
      })
      //VARIETY UPDATE
      .addCase(updateVarietyThunk.pending, (state) => {
        state.varietyStatus = "loading";
      })
      .addCase(updateVarietyThunk.fulfilled, (state, action) => {
        state.varietyStatus = "succeeded";
      })
      .addCase(updateVarietyThunk.rejected, (state, action) => {
        state.varietyStatus = "failed";
      })
      //VARIETY DELETE
      .addCase(deleteVarietyThunk.pending, (state) => {
        state.varietyStatus = "loading";
      })
      .addCase(deleteVarietyThunk.rejected, (state, action) => {
        state.varietyStatus = "failed";
      })
      .addCase(deleteVarietyThunk.fulfilled, (state, action) => {
        state.varietyStatus = "succeeded";
      });
  },
});

export const selectMaintainerState = (state: RootState) => state.maintainer;

export const {
  resetIdleGrower,
  resetIdleClient,
  resetIdleSpecie,
  resetIdleVariety,
  resetIdleFieldman,
} = maintainerSlice.actions;

export default maintainerSlice.reducer;
