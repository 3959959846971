import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "src/app/store";
import { Manifold } from "src/models/interfaces/manifold";
import { fetchFieldDetail } from "src/services/field";
import {
  fetchManifoldById,
  fetchManifoldDates,
} from "src/services/manifoldsPH";

interface FieldDetailState {
  fieldData: FieldData;
  manifoldData: Manifold;
  manifoldSelected: { id: number; label: string } | undefined;
  manifoldDates: [{ id: number; label: string }] | [];
  status: "idle" | "loading" | "failed";
  statusManifold: "idle" | "loading" | "failed";
  statusManifoldDates: "idle" | "loading" | "failed";
  error: any;
}

interface FieldData {
  id: number;
  name: string;
  growerName: string;
  fieldNumber: number;
  specieName: string;
  varietyName: string;
  clientName: string;
  generalStatus: string;
  fieldmanName: string;
  lastManifoldId: number;
  performance: null | any; // Replace 'any' with the actual type of performance if known
  totals: {
    RED: number;
    GREEN: number;
    YELLOW: number;
  };
}

// Thunk to fetch field detail
export const fetchFieldDetailThunk = createAsyncThunk(
  "fieldDetail/fetchFieldDetail",
  async (fieldId: string) => {
    try {
      const response = await fetchFieldDetail(fieldId);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch field detail");
    }
  }
);

//Thunk to fetch manifold by id
export const fetchManifoldByIdThunk = createAsyncThunk(
  "fieldDetail/fetchManifoldById",
  async (id: string) => {
    try {
      const response = await fetchManifoldById(id);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch manifold");
    }
  }
);

//Thunk to fetch manifold dates
export const fetchManifoldDatesThunk = createAsyncThunk(
  "fieldDetail/fetchManifoldDates",
  async (fieldId: string) => {
    try {
      const response = await fetchManifoldDates(fieldId);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch manifold dates");
    }
  }
);

const fieldDetailSlice = createSlice({
  name: "fieldDetail",
  initialState: {
    fieldData: {},
    manifoldData: {},
    manifoldSelected: undefined,
    manifoldDates: [],
    status: "idle",
    statusManifold: "idle",
    statusManifoldDates: "idle",
    error: null,
  } as FieldDetailState,
  reducers: {
    setManifoldSelected: (state, action) => {
      state.manifoldSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFieldDetailThunk.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchFieldDetailThunk.fulfilled, (state, action) => {
        state.status = "idle";
        state.fieldData = action.payload;
      })
      .addCase(fetchFieldDetailThunk.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchManifoldByIdThunk.pending, (state) => {
        state.statusManifold = "loading";
        state.error = null;
      })
      .addCase(fetchManifoldByIdThunk.fulfilled, (state, action) => {
        state.statusManifold = "idle";
        state.manifoldData = action.payload;
      })
      .addCase(fetchManifoldByIdThunk.rejected, (state, action) => {
        state.statusManifold = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchManifoldDatesThunk.pending, (state) => {
        state.statusManifoldDates = "loading";
        state.error = null;
      })
      .addCase(fetchManifoldDatesThunk.fulfilled, (state, action) => {
        state.statusManifoldDates = "idle";
        state.manifoldDates = action.payload.map((item: any) => ({
          id: item.id,
          label: new Date(item.createdAt).toLocaleString(),
        }));
        state.manifoldSelected = state.manifoldDates[0];
      })
      .addCase(fetchManifoldDatesThunk.rejected, (state, action) => {
        state.statusManifoldDates = "failed";
        state.error = action.error.message;
      });
  },
});

// Export the action creator and reducer
export const selectFieldDetailState = (state: RootState) => state.fieldDetail;
export const selectManifoldSelected = (state: RootState) =>
  state.fieldDetail.manifoldSelected;
export const selectManifoldData = (state: RootState) =>
  state.fieldDetail.manifoldData;
export const { setManifoldSelected } = fieldDetailSlice.actions;

export default fieldDetailSlice.reducer;
