import React, { ReactNode } from "react";
import classes from "./Typography.module.css";
interface TextProps {
  color?: string;
  size?: "xs" | "s" | "m" | "l" | "xl";
  children?: ReactNode[] | ReactNode;
}
const Text = ({ color, children, size }: TextProps) => {
  const colorClass = color ? classes[color] : classes["black"];
  const sizeClass = size ? classes[size] : classes["m"];
  return (
    <p className={[classes.defaultText, colorClass, sizeClass].join(" ")}>
      {children}
    </p>
  );
};

export default Text;
