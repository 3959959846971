
import { AppBar, Toolbar, Typography, Button, Container } from "@mui/material";


import Logo from '../../assets/icon.png';


const styles = {
  heroContent: {
    padding: '4rem 0',
    background: '#242424',
    color: '#fff',
    textAlign: 'center',
  },
  heroText: {
    marginBottom: '2rem',
    color: '#54B435',
    fontFamily: "Roboto",
    fontWeight: 700
  },
  heroDesc: {
    color: "white",
    marginBottom: '2rem',
    fontWeight: 400

  },
  heroLogo: {
    maxWidth: 200,
  },
  section: {
    padding: '4rem 0',
  },
  sectionTitle: {
    color: '#54B435',
    fontFamily: "Roboto",
    fontWeight: 700
  },
  sectionText: {
    padding: "0 10rem"
  },
  footer: {
    background: '#242424',
    color: '#fff',
    padding: '2rem 0',
    textAlign: 'center',
  },
};

const HomePage = () => {
  return (
    <div>
      <div style={styles.heroContent}>
        <Container maxWidth="sm">
          <Typography component="h1" variant="h2" style={styles.heroText}>
            Revolutionizing Farming Technology
          </Typography>
          <Typography variant="h5" style={styles.heroDesc}>
            Empowering Farmers with Smart Solutions
          </Typography>
          
        </Container>
      </div>

      <div style={styles.section}>

        <Container maxWidth="md">
          <Typography variant="h4" align="center" gutterBottom style={styles.sectionTitle}>
            Our Motivation
          </Typography>
          <Typography variant="h6" align="center" color="textSecondary" paragraph style={styles.sectionText}>
            At Zeed, we believe that technology has the power to revolutionize the agricultural industry.
            Our motivation is to bridge the gap between farming and cutting-edge technology, enabling farmers
            to harness the full potential of digital innovations and achieve sustainable growth.
          </Typography>
        </Container>
      </div>

      <div style={styles.section}>
        <Container maxWidth="md">
          <Typography variant="h4" align="center" gutterBottom style={styles.sectionTitle}>
            Our Vision
          </Typography>
          <Typography variant="h6" align="center" color="textSecondary" paragraph style={styles.sectionText}>
            Our vision is to be the leading provider of technological services for farming companies worldwide.
            We aim to empower farmers with intuitive and intelligent solutions that optimize their operations,
            increase productivity, and contribute to a more efficient and sustainable agricultural ecosystem.
          </Typography>
        </Container>
      </div>

      {/* Additional sections and content can be added here */}
      <footer style={styles.footer}>
        <Typography variant="body1" color="inherit">
          &copy; {new Date().getFullYear()} Zeed. All rights reserved.
        </Typography>
      </footer>
    </div>
  );
};

export default HomePage;