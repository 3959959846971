import axios from "axios";
import { refreshToken } from "./auth";
import { API_URL } from "src/config/environment";

const api = axios.create({
  baseURL: API_URL,
});

// Function to refresh the token
const refreshTokenAxios = async () => {
  try {
    // Perform token refresh logic (e.g., make a request to refresh endpoint)
    const tokenLocal = localStorage.getItem("refreshToken");
    if (!tokenLocal) throw new Error("no token in local storage");

    const response: any = await refreshToken(tokenLocal);
    const { token, refresh_token, user_id } = response.data.session;
    saveTolocalStorage(token, refresh_token, user_id);

    return token;
  } catch (error) {
    // Handle token refresh error (e.g., redirect to login)
    console.error("Error refreshing token:", error);
    throw error;
  }
};

// Add a request interceptor to attach the token to each request
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      if (config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle token expiration
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Check if the error response indicates an expired token
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Refresh the token
        const newToken = await refreshTokenAxios();

        // Update the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${newToken}`;

        // Retry the original request with the new token
        return axios(originalRequest);
      } catch (error) {
        // Handle token refresh error (e.g., redirect to login)
        console.error("Error refreshing token:", error);
        throw error;
      }
    }

    return Promise.reject(error);
  }
);

const saveTolocalStorage = (
  token: string,
  refreshToken: string,
  userId: string
) => {
  localStorage.setItem("user", userId);
  localStorage.setItem("token", token);
  localStorage.setItem("refreshToken", refreshToken);
  const currentDate = new Date();
  currentDate.setHours(currentDate.getHours() + 1);
  localStorage.setItem("expirationDate", currentDate.toISOString());
};

export default api;
