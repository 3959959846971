import api from "./api";

const FIELD_URL = "/field";

export const fetchFieldById = async (id: number) => {
  return await api.get(`${FIELD_URL}/${id}`);
};

export const fetchFieldsByProgramAndActive = async (programId: number) => {
  return await api.get(
    `${FIELD_URL}/list-by-program-status-active/${programId}`
  );
};

export const fetchFieldsCandidatesForOrder = async (orderId: number) => {
  return await api.get(
    `${FIELD_URL}/list-fields-candidates-for-order/${orderId}`
  );
};

export const fetchFieldDetail = async (fieldId: string) => {
  return await api.get(`${FIELD_URL}/field-detail-dashboard/${fieldId}`);
};

export const attachFieldToOrder = async (fieldId: number, orderId: number) => {
  return await api.put(
    `${FIELD_URL}/attach-field-to-order/${fieldId}/${orderId}`
  );
};

export const deleteField = async (fieldId: number) => {
  return await api.delete(`${FIELD_URL}/${fieldId}`);
};

export const fetchFieldsBooksPH = async () => {
  return await api.get(`${FIELD_URL}/ph/book`);
};

export const fetchFieldsManifoldsPH = async (fieldIds: number[]) => {
  let idsQuery = fieldIds.join(",");
  return await api.get(`${FIELD_URL}/ph/manifold?fieldIds=${idsQuery}`);
};
