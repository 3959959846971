import React from "react";
import classes from "./ImageHolder.module.css";
const ImageHolder = (props: any) => {
  return (
    <img
      src={props.src}
      alt="image_card"
      className={
        props.landscape ? classes.img_card_landscape : classes.img_card
      }
    />
  );
};

export default ImageHolder;
