import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import InspectionReportScreen from "src/screens/InspectionReport/InspectionReportScreen";
import LoadingScreen from "src/screens/LoadingScreen/LoadingScreen";
import MaintainerScreen from "src/screens/MaintainerScreen/MaintainerScreen";
import OrderDetailsScreen from "src/screens/OrderDetails/OrderDetailsScreen";
import PrivacyScreen from "src/screens/Privacy/Privacy";
import SupportScreen from "src/screens/SupportScreen/SupportScreen";
import LoginScreen from "../screens/LoginScreen/LoginScreen";
import ManifoldImagesScreen from "../screens/ManifoldImagesScreen/ManifoldImagesScreen";
import ManifoldImagesNewScreen from "../screens/ManifoldImagesScreen/ManifoldImagesNewScreen";
import OrdersScreen from "../screens/OrdersScreen/OrdersScreen";
import LandingScreen from "../screens/Landing/Landing"
import FieldsScreen from "../screens/FieldsScreen/FieldScreen"
import FieldDetailScreen from "../screens/FieldDetailScreen/FieldDetailScreen"
import { ProtectedLayout } from "./Layouts/ProtectedLayout";
import { routes } from "./routes";
import SideBar from "./SideBar/SideBar";
import ProgramConfigScreen from "src/screens/ProgramConfig/ProgramConfigScreen";
import { createTheme } from "src/theme";
import { ThemeProvider } from "@mui/material";
import AppContainer from "../components/AppContainer/AppContainer"
import ReportScreen from "src/screens/ReportScreen/ReportScreen";

const Routing = () => {
  const theme = createTheme()
  return (
  
      <ThemeProvider theme={theme}>
        <AppContainer>
      <BrowserRouter >
      <SideBar/>
        <Routes>
        <Route path="/" element={<Navigate to={routes.login} />} />
        <Route caseSensitive path={routes.login} element={<LoginScreen />}/>
        <Route element={<ProtectedLayout />}>
          <Route caseSensitive path={routes.orders} element={<OrdersScreen />} />
          <Route caseSensitive path={routes.orderDetails} element={<OrderDetailsScreen/>}/>
          <Route caseSensitive path={routes.inspectionReport} element={<InspectionReportScreen/>}/>
          <Route caseSensitive path={routes.maintainer} element={<MaintainerScreen/>}/>
          <Route caseSensitive path={routes.programConfig} element={<ProgramConfigScreen/>}/>
          <Route caseSensitive path={routes.fields} element={<FieldsScreen/>}/>
          <Route caseSensitive path={routes.fieldDetail} element={<FieldDetailScreen/>}/>
          <Route caseSensitive path={routes.report} element={<ReportScreen/>}/>
        </Route>
        <Route caseSensitive path={routes.manifoldImages} element={<ManifoldImagesScreen />}/>
        <Route caseSensitive path={routes.manifoldImagesNew} element={<ManifoldImagesNewScreen />}/>

        
          <Route caseSensitive path={routes.support} element={<SupportScreen/>}/>
          <Route caseSensitive path={routes.privacy} element={<PrivacyScreen/>}/>
          <Route caseSensitive path={routes.loading} element={<LoadingScreen/>}/>
          <Route caseSensitive path={routes.landing} element={<LandingScreen/>}/>
        
          {/* <Route element={<NotFoundPage />} path="*" /> */}
        </Routes>
      </BrowserRouter>
      </AppContainer>
      </ThemeProvider>
  

  )
}

export default Routing;