import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import PropTypes from "prop-types";
import { deleteClientThunk, deleteGrowerThunk, deleteSpecieThunk, deleteVarietyThunk } from "../maintainer-slice";
import { useDispatch } from "react-redux";
const DeleteModal = ({ open, onClose, onDelete, item, name}) => {
  const dispatch = useDispatch();
  const handleDelete = () => {
    switch (name) {
      case "variety":
        dispatch(deleteVarietyThunk(item.id));
        break;
      case "specie":
        dispatch(deleteSpecieThunk(item.id));
        break;
      case "client":
        dispatch(deleteClientThunk(item.id));
        break;
      case "grower":
        dispatch(deleteGrowerThunk(item.id));
        break;
      default:
        break;
    }
    onDelete();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        Delete {item.title || ""}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete {item.name || "this item"}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleDelete} color="error">Delete</Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

export default DeleteModal;