import axios from "./axios";
import api from "./api";

export const fetchOrders = async () => {
  return await axios.get("/growers");
};

export const fetchOrderMetadata = async (id: String) => {
  return await axios.get(`/order_metadata/${id}`);
};

export const fetchOrdersMetadata = async (
  programId: number,
  seasonId: number
) => {
  return await axios.get(
    `/allOrdersMetadata?program_id=${programId}&season_id=${seasonId}`
  );
};

export const fetchAllOrders = async (programId: number, seasonId: number) => {
  return await axios.get(
    `/orders?program_id=${programId}&season_id=${seasonId}`
  );
};

export const fetchAllOrdersWithFields = async (
  programId: number,
  seasonId: number
) => {
  return await api.get(
    `/order/find-with-fields?programId=${programId}&seasonId=${seasonId}`
  );
};

export const fetchAllOrdersWithFieldsFromUser = async (userId: number) => {
  return await api.get(`/order/find-with-fields-from-user/${userId}`);
};

export const fetchOrderWithFields = async (orderId: number) => {
  return await api.get(`/order/find-with-fields/${orderId}`);
};

export const fetchOrder = async (orderId: string) => {
  return await api.get(`/order/find-with-fields/${orderId}`);
};

export const createOrder = async (request: {
  seasonId: number | null;
  clientId: number | null;
  specieId: number | null;
  varietyId: number | null;
  userId: number | null;
  programId: number | null;
  ratioUpper?: number | null;
  ratioLower?: number | null;
  base?: number | null;
  requiredSurface?: number | null;
}) => {
  const response = api.post("/order?seasonDefault=true", request);
  return response;
};

export const attachFieldmanToORder = async (request: {
  userId: number | null;
  orderId: number | null;
}) => {
  const response = api.post(
    `/order/attach-user/${request.orderId}/${request.userId}`
  );
  return response;
};

export const deleteOrder = async (orderId: number) => {
  return await api.delete(`/order/${orderId}`);
};
