import React, { ReactNode } from "react";
import classes from "./Typography.module.css";
interface TitleProps {
  color?: string;
  size?: "xs" | "s" | "m" | "l" | "xl";
  children?: ReactNode[] | ReactNode;
}
const Title = ({ color, children, size }: TitleProps) => {
  const colorClass = color ? classes[color] : classes["black"];
  const sizeClass = size ? classes[size] : classes["m"];

  return <h1 className={[colorClass, sizeClass].join(" ")}>{children}</h1>;
};

export default Title;
