import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchOrder } from "src/services/orders";
import { RootState } from "../../app/store";

export interface OrderDetailState {
  order: any;
  status: "idle" | "loading" | "failed";
  selectedField: any;
}

const initialState: OrderDetailState = {
  order: {},
  status: "idle",
  selectedField: {},
};

// export const ordersAsync = createAsyncThunk("orders/fetchOrders", async () => {
//   const response = await fetchOrders();
//   return response.data;
// });

export const orderDetailAsync = createAsyncThunk(
  "orders/fetchOrder",
  async ({ orderId }: { orderId: string }) => {
    const response = await fetchOrder(orderId);
    return response.data;
  }
);

export const ordersDetailsSlice = createSlice({
  name: "ordersDetailsSlice",
  initialState,
  reducers: {
    selectField: (state, action: PayloadAction<string>) => {
      state.selectedField = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(orderDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(orderDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.order = action.payload;
      })
      .addCase(orderDetailAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { selectField } = ordersDetailsSlice.actions;

//SELECTORS
export const selectOrderState = (state: RootState) => state.orderDetail;

export default ordersDetailsSlice.reducer;
