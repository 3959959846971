import api from "./api";

export const createClient = async (name: string, programId: number) => {
  return await api.post("/client", {
    name,
    programId,
  });
};

export const getClients = async (programId: number) => {
  return await api.get(`/client?programId=${programId}`);
};

export const editClient = async (id: number, name: string) => {
  return await api.put(`/client/${id}`, {
    name,
  });
};

export const deleteClient = async (id: number) => {
  return await api.delete(`/client/${id}`);
};

export const createSpecie = async (name: string, programId: number) => {
  return await api.post("/specie", {
    name,
    programId,
  });
};

export const getSpecies = async (programId: number) => {
  return await api.get(`/specie?programId=${programId}`);
};

export const editSpecie = async (id: number, name: string) => {
  return await api.put(`/specie/${id}`, {
    name,
  });
};

export const deleteSpecie = async (id: number) => {
  return await api.delete(`/specie/${id}`);
};

export const createVariety = async (name: string, programId: number) => {
  return await api.post("/variety", {
    name,
    programId,
  });
};

export const getVarieties = async (programId: number) => {
  return await api.get(`/variety?programId=${programId}`);
};

export const editVariety = async (id: number, name: string) => {
  return await api.put(`/variety/${id}`, {
    name,
  });
};

export const deleteVarity = async (id: number) => {
  return await api.delete(`/variety/${id}`);
};

export const createGrower = async (
  name: string,
  programId: number,
  email: string,
  location: string
) => {
  return await api.post("/grower", {
    name,
    programId,
    email,
    location,
  });
};

export const editGrower = async (
  id: number,
  name: string,
  programId: number,
  email: string,
  location: string
) => {
  return await api.put(`/grower/${id}`, {
    name,
    programId,
    email,
    location,
  });
};

export const getGrowers = async (programId: number) => {
  return await api.get(`/grower?programId=${programId}`);
};

export const deleteGrower = async (id: number) => {
  return await api.delete(`/grower/${id}`);
};
