import { ReactNode } from "react";
import { styled } from "@mui/material";

interface Props {
  children?: ReactNode[] | ReactNode;
}

const LayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

export default function AppContainer({ children }: Props) {
  return <LayoutContainer>{children}</LayoutContainer>;
}
